import { Alert, Button, Grid, Snackbar } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AUTHORIZATION, HOSTNAME } from "../../common/constants";
import GenericForm from "../../components/GenericForm";
import HorizontalNav from "../../components/HorizontalNav";
import authTokenForAPI from "../../utils/authTokenForAPI";
import { isAPISuccess } from "../../utils/commonUtils";

const AccomoAllotment = () => {
  const [fieldState, setFieldState] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  const onFieldChange = (key, val) => {
    setFieldState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const fieldOptions = [
    {
      key: "name",
      type: "textbox",
      label: "Name",
      value: fieldState.name,
    },
    {
      key: "relativeName",
      type: "textbox",
      label: "Father's/Husband's Name",
      value: fieldState.relativeName,
    },
    {
      key: "gender",
      type: "dropdown",
      label: "Gender",
      value: fieldState.gender,
      options: [
        {
          key: "M",
          label: "Male",
          value: "M",
        },
        {
          key: "F",
          label: "Female",
          value: "F",
        },
      ],
    },
    {
      key: "address",
      type: "textbox",
      label: "Address",
      value: fieldState.address,
    },
    {
      key: "city",
      type: "textbox",
      label: "City/Village",
      value: fieldState.city,
    },

    {
      key: "contactNumber",
      type: "number",
      label: "Contact Number",
      value: fieldState.contactNumber,
    },
    {
      key: "numberOfPerson",
      type: "number",
      label: "Num of Persons",
      value: fieldState.numberOfPerson,
    },
    {
      key: "blockNumber",
      type: "number",
      label: "Block Number",
      value: fieldState.blockNumber,
    },
  ];

  const saveAccomo = () => {
    console.log(fieldState);

    const authToken = authTokenForAPI();
    trackPromise(
      axios
        .post(
          `${HOSTNAME}/accommodationAllotment`,
          {
            ...fieldState,
          },
          {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setFieldState({});
            setSnack({
              open: true,
              severity: "success",
              msg: `Block ${fieldState.blockNumber} allotted succesfully`,
            });
            window.location.reload();
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Block cannot be allotted, please try again.",
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Block cannot be allotted, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };

  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <h2>Accomodation Allotment</h2>
      <GenericForm fieldOptions={fieldOptions} onFieldChange={onFieldChange} />

      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{ margin: "20px", float: "right" }}
            onClick={saveAccomo}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AccomoAllotment;
