import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AUTHORIZATION, HOSTNAME, ROLES } from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";
import { rows } from "./listSewadarUtils";
import * as XLSX from "xlsx";

const ListSewadar = () => {
  const [sewadarList, setSewadarList] = useState([]);
  const columns = [
    { field: "id", headerName: "Sewadar ID" },
    { field: "name", headerName: "Name", width: 200 },
    { field: "relativeName", headerName: "Relative Name", width: 200 },
    { field: "gender", headerName: "Gender" },
    { field: "address", headerName: "Address", width: 300 },
    { field: "city", headerName: "City", width: 120 },
    { field: "pinCode", headerName: "PinCode" },
    { field: "mobileNumber", headerName: "Mobile Number", width: 150 },
    { field: "emergencyNumber", headerName: "Emergency Number", width: 150 },
    { field: "birthDate", headerName: "DOB" },
    { field: "initiationDate", headerName: "Date of initiation", width: 200 },
    { field: "initiationStatus", headerName: "Initiation Status", width: 200 },
    { field: "qualification", headerName: "Qualification", width: 120 },
    { field: "profession", headerName: "Profession", width: 150 },
    { field: "maritalStatus", headerName: "Marital Status", width: 150 },
    { field: "bloodGroup", headerName: "Blood Group", width: 120 },
    { field: "department", headerName: "Department", width: 180 },
    { field: "aadharNumber", headerName: "Aadhar Number", width: 200 },
    { field: "zoneBatchNumber", headerName: "Zone Batch Number", width: 200 },
    { field: "zoneDepartment", headerName: "Zone Department", width: 200 },
    { field: "introducedBy", headerName: "Introduced By", width: 200 },
    { field: "centre", headerName: "Centre", width: 150 },
    { field: "subCentre", headerName: "Sub Centre", width: 150 },
    { field: "photoBadge", headerName: "Photo Badge", width: 120 },
    { field: "badgePrinted", headerName: "Badge Printed", width: 120 },
    { field: "isActive", headerName: "Active", width: 120 },
    { field: "remarks", headerName: "Remarks" },
  ];
  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/sewadars`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setSewadarList(res.data);
        })
    );
  }, []);

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  const downloadAllData = () => {
    const ws = XLSX.utils.json_to_sheet(sewadarList);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    XLSX.writeFile(wb, "download.xlsx");
  };

  return (
    <>
      <HorizontalNav navLinks={navLinks} pageName="List of Sewadars" />
      <Box display={"flex"} justifyContent={"space-between"}>
        <h2>List sewadar</h2>
        {localStorage.getItem(ROLES) === "ROLE_SUPER_ADMIN" && (
          <Button
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
            }}
            onClick={downloadAllData}
          >
            Download
          </Button>
        )}
      </Box>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          density="compact"
          showCellRightBorder
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          rows={sewadarList}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={(e) => console.log(e)}
        />
      </div>
    </>
  );
};

export default ListSewadar;
