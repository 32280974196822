import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AUTHORIZATION, HOSTNAME } from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";
// import { rows } from "./listSewadarUtils";

const ListCentres = () => {
  const [centresList, setCentresList] = useState([]);

  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);
          setCentresList(res.data);
        })
    );
  }, []);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Centre name", width: 150 },
    { field: "prefix", headerName: "Prefix" },
    {
      field: "subcentres",
      headerName: "Sub Centres",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 900,
      valueGetter: (params) =>
        params.row.subCentres
          .map(function (elem) {
            return elem.name;
          })
          .join(", "),
    },
  ];

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  return (
    <>
      <HorizontalNav navLinks={navLinks} pageName="List of Centres" />
      <h2>List Centres</h2>
      <div style={{ maxWidth: "1100px" }}>
        <DataGrid
          density="compact"
          showCellRightBorder
          autoHeight
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          rows={centresList}
          columns={columns}
        />
      </div>
    </>
  );
};

export default ListCentres;
