export const HOSTNAME = "https://api.rssb-sre.online";
// export const HOSTNAME = "http://localhost:8080";
export const HOSTNAME_PATHI = "https://api.rssb-sre.online/pathi";
// export const HOSTNAME_PATHI = "http://localhost:8080/pathi";
// export const HOSTNAME = "http://localhost:5000";
// export const HOSTNAME = "http://192.168.29.67:9090";
// export const HOSTNAME =
//   "http://rssbsystem-env.eba-mv9yejg8.ap-south-1.elasticbeanstalk.com";
export const AUTHORIZATION = "Authorization";
export const ROLES = "Roles";
export const DEPARTMENT = "Department";
export const CENTRE = "Centre";
export const CAMERA_TIMEOUT = 20000;

export const MAJOR_CENTRE = "MAJOR_CENTRE";
export const OWN_CENTRE = "OWN_CENTRE";
export const OTHER_CENTRE = "OTHER_CENTRE";
export const OTHER_MAJOR_CENTRE = "OTHER_MAJOR_CENTRE";
export const BEAS = "BEAS";

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const securityBearerToken =
  "Bearer v2.local.3qnzbWNOLxgWtXD7r_HT5qxnP0ViZVZO1xQLcocq8pFOzFg30su4Iwz1QL6fK4bgDXTvaJzHi9QOIce2OUoysYKmaDYZHALVRGQomjyiuOlyGti1OVVvTh2M9iaVQgVScVol3m0FToL9lwxyfpO_2h7YaLYPAMATZJTvzO3NQjTYdc1OBb-m8m4oqYDGKIGBI_bebxbytnunJ-OWhaf-diS9Vr3JsNpM_-RWbpwjXIkcoxWsKt2acQURNps9knbiMrY.bnVsbA";
