import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import AddSewadar from "./screens/Sewadar/AddSewadar/AddSewadar";
import EditSewadarSearch from "./screens/Sewadar/EditSewadar/EditSewadarSearch";
import HomePage from "./screens/HomePage";
import ListSewadar from "./screens/Sewadar/ListSewadar/ListSewadar";
import ListCentres from "./screens/Information/ListCentres/ListCentres";
import ListSubCentres from "./screens/Information/ListSubcentres/ListSubCentres";
import ListDepartments from "./screens/Information/ListDepartments/ListDepartments";
import EditSewadarForm from "./screens/Sewadar/EditSewadar/EditSewadarForm";
import LiveCount from "./screens/Sewadar/LiveCount/LiveCount";
import AddSewadarBulk from "./screens/Sewadar/AddSewadarBulk/AddSewadarBulk";
import SewadarDetails from "./screens/Sewadar/SewadarDetails/SewadarDetails";
import ScanController from "./screens/Attendance/ScanController";
import Login from "./screens/Login/Login";
import SewadarAddtional from "./screens/Sewadar/SewadarAdditional/SewadarAdditional";
import GenerateReport from "./screens/Information/GenerateReport/GenerateReport";
import ZXingScanner from "./screens/Attendance/experiments/zxing";
import LiveCountDept from "./screens/Sewadar/LiveCount/LiveCountDept";
import ManualAttendance from "./screens/Attendance/ManualAttendance";
import AccomoAllotment from "./screens/Accomodation/AccomoAllotment";
import GetAllotment from "./screens/Accomodation/GetAllotment";
import ListDesignation from "./screens/Master/Designation/ListDesignation";
import ConfigureCentre from "./screens/Master/Centre/ConfigureCentre";
import ListCentre from "./screens/Master/Centre/ListCentre";
import ConfigureDesignation from "./screens/Master/Designation/ConfigureDesignation";
import ConfigureLedger from "./screens/Master/Ledger/ConfigureLedger";
import ListLedger from "./screens/Master/Ledger/ListLedger";
import ConfigureShabadMedia from "./screens/Master/Media/ConfigureShabadMedias";
import ListShabadMedia from "./screens/Master/Media/ListShabadMedias";
import ConfigureShabad from "./screens/Master/Shabad/ConfigureShabad";
import ListShabad from "./screens/Master/Shabad/ListShabad";
import ScheduleReport from "./screens/Report/ScheduleReport";
import SatsangSchedule from "./screens/Transaction/SatsangSchedule";
import SatsangScheduleDateWise from "./screens/Transaction/SatsangScheduleDateWise";
import GateCount from "./screens/Security/GateCount";
import GateList from "./screens/Security/GateList";

function App() {
  return (
    <Router>
      <BaseLayout>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/addSewadar" element={<AddSewadar />} />
          <Route exact path="/editSewadar" element={<EditSewadarSearch />} />
          <Route
            exact
            path="/editSewadar/:sewadarId"
            element={<EditSewadarForm />}
          />
          <Route exact path="/liveCount" element={<LiveCount />} />
          <Route exact path="/liveCountDept" element={<LiveCountDept />} />
          <Route exact path="/addSewadarBulk" element={<AddSewadarBulk />} />
          <Route exact path="/sewadarDetails" element={<SewadarDetails />} />
          <Route
            exact
            path="/sewadarAdditional"
            element={<SewadarAddtional />}
          />
          <Route exact path="/listSewadar" element={<ListSewadar />} />
          <Route exact path="/centres" element={<ListCentres />} />
          <Route exact path="/subcentres" element={<ListSubCentres />} />
          <Route exact path="/departments" element={<ListDepartments />} />
          <Route exact path="/markAttendance" element={<ScanController />} />
          <Route
            exact
            path="/markAttendanceManual"
            element={
              <ScanController defaultScanning={false} defaultAfterScan={true} />
            }
          />
          <Route exact path="/markManualTime" element={<ManualAttendance />} />
          <Route exact path="/generateReport" element={<GenerateReport />} />
          <Route exact path="/experiments" element={<ZXingScanner />} />
          <Route
            exact
            path="/accomodation/allotment"
            element={<AccomoAllotment />}
          />
          <Route
            exact
            path="/accomodation/getAllotment"
            element={<GetAllotment />}
          />

          <Route exact path="/designation" element={<ListDesignation />} />
          <Route
            exact
            path="/designation/add"
            element={<ConfigureDesignation />}
          />
          <Route
            exact
            path="/designation/edit/:id"
            element={<ConfigureDesignation />}
          />
          <Route exact path="/ledger" element={<ListLedger />} />
          <Route exact path="/ledger/add" element={<ConfigureLedger />} />
          <Route exact path="/ledger/edit/:id" element={<ConfigureLedger />} />
          <Route exact path="/centre" element={<ListCentre />} />
          <Route exact path="/centre/add" element={<ConfigureCentre />} />
          <Route exact path="/centre/edit/:id" element={<ConfigureCentre />} />
          <Route exact path="/shabadMedia" element={<ListShabadMedia />} />
          <Route
            exact
            path="/shabadMedia/add"
            element={<ConfigureShabadMedia />}
          />
          <Route
            exact
            path="/shabadMedia/edit/:id"
            element={<ConfigureShabadMedia />}
          />
          <Route exact path="/shabad" element={<ListShabad />} />
          <Route exact path="/shabad/add" element={<ConfigureShabad />} />
          <Route exact path="/shabad/edit/:id" element={<ConfigureShabad />} />
          <Route exact path="/schedule" element={<SatsangSchedule />} />
          <Route
            exact
            path="/scheduleDateWise"
            element={<SatsangScheduleDateWise />}
          />
          <Route exact path="/scheduleReport" element={<ScheduleReport />} />

          <Route exact path="/gateCount" element={<GateCount />} />
          <Route exact path="/gateList" element={<GateList />} />
        </Routes>
      </BaseLayout>
    </Router>
  );
}

export default App;
