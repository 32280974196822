import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { CENTRE } from "../common/constants";

const CentreInfo = () => {
  const [sp, setSp] = useState("");
  useEffect(() => {
    setSp(localStorage.getItem(CENTRE));
  }, [sp]);
  return (
    <Box textAlign={"right"} margin={"16px 16px 0"} fontSize={"16px"} fontWeight={"bold"}>
      {!(sp === "undefined" && sp === "null" && !!sp)
        ? "Satsang Place:" + sp
        : ""}
    </Box>
  );
};

export default CentreInfo;
