export const fieldOptions = (fieldState, gates) => [
  {
    key: "gate",
    type: "dropdown",
    label: "Gate Number",
    value: fieldState.gate,
    options: [...gates],
  },
  {
    key: "count",
    type: "number",
    label: "Count",
    value: fieldState.count,
  },
];
