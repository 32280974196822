export const primaryColor = "#8b0000";
export const secondaryColor = "#fbf9cc";
export const ternaryColor = "#fffaf0";

export const homepageBtn = {
  width: "100%",
  height: "100px",
};
export const homeIconStyle = { padding: "10px", height: "35px", width: "35px" };

export const textboxStyle = {
  background: "#fffaf0",
  "& label.Mui-focused": {
    color: primaryColor,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: primaryColor,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: primaryColor,
    },
    "&:hover fieldset": {
      borderColor: primaryColor,
    },
    "&.Mui-focused fieldset": {
      borderColor: primaryColor,
    },
  },
};
