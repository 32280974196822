import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { trackPromise } from "react-promise-tracker";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AUTHORIZATION,
  CENTRE,
  HOSTNAME,
  ROLES,
} from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";
import {
  primaryColor,
  textboxStyle,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";
import moment from "moment";
import * as XLSX from "xlsx";
import { Box } from "@mui/system";
import { useEffect } from "react";

const SewadarDetails = () => {
  const [sewadarBadge, setSewadarBadge] = useState("");
  const [details, setDetails] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const [delState, setDelState] = useState(false);

  const onFieldChange = (val) => {
    setSewadarBadge(val);
  };

  const getDeleteEndpoint = (centre) => {
    if (centre.toUpperCase() === "BEAS") {
      return "sewadarBeasAttendances";
    }
    return "sewadarOtherMCAttendances";
  };

  useEffect(() => {
    if (delState) onSubmitClick();
    setDelState(false);
  }, [delState]);
  
  const onSubmitClick = () => {
    trackPromise(
      axios
        .get(
          `${HOSTNAME}/sewadars/${sewadarBadge}?centre=${localStorage.getItem(
            CENTRE
          )}`,
          {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setDetails(res.data);
          } else {
            setDetails({});
            setSnack({
              open: true,
              severity: "error",
              msg: "Sewadar ID not valid, please try with a valid ID.",
            });
          }
        })
        .catch(() => {
          setDetails({});
          setSnack({
            open: true,
            severity: "error",
            msg: "Sewadar ID not valid, please try with a valid ID.",
          });
        })
    );
  };

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  const attendanceColumns = [
    { field: "centre", headerName: "Place of Sewa", width: 150 },
    { field: "department", headerName: "Department", width: 200 },
    {
      field: "clockIn",
      headerName: "ClockIn",
      width: 280,
      valueGetter: (params) =>
        params.value
          ? moment.utc(params.value).local().format("DD-MMM-YYYY h:mm A")
          : "",
    },
    {
      field: "clockOut",
      headerName: "ClockOut",
      width: 280,
      valueGetter: (params) =>
        params.value
          ? moment.utc(params?.value).local().format("DD-MMM-YYYY h:mm A")
          : "",
    },
  ];

  const downloadAllData = () => {
    const ws = XLSX.utils.json_to_sheet(details.sewadarAttendance);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    XLSX.writeFile(wb, `${details.id}_${details.name}.xlsx`);
  };

  const deleteAttendance = (id, centre) => {
    const delEndpoint = getDeleteEndpoint(centre);

    trackPromise(
      axios.delete(
        `${HOSTNAME}/${delEndpoint}/${id}?centre=${localStorage.getItem(
          CENTRE
        )}`,
        {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        }
      )
    )
      .then((res) => {
        if (isAPISuccess(res.status)) {
          setSnack({
            open: true,
            severity: "success",
            msg: "Deleted Successfully",
          });
          setDelState(true);
        } else {
          setSnack({
            open: true,
            severity: "error",
            msg: "Sewadar ID not valid, please try with a valid ID.",
          });
        }
      })
      .catch(() => {
        setSnack({
          open: true,
          severity: "error",
          msg: "Sewadar ID not valid, please try with a valid ID.",
        });
      });
  };

  const beasColumns = [
    { field: "centre", headerName: "Centre" },
    { field: "attendanceDate", headerName: "Attendance Date", width: 750 },
    {
      field: "delete",
      headerName: "",
      width: 50,
      align: "centre",
      renderCell: (row) => (
        <div style={{ margin: "auto" }}>
          <DeleteIcon
            style={{
              color: primaryColor,
            }}
            onClick={() => deleteAttendance(row?.id, row?.row?.centre)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Sewadar Details" />
      <h2>Sewadar Details</h2>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        mb={2}
      >
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            placeholder="Sewadar Badge Number"
            onChange={(e) => onFieldChange(e.target.value)}
            fullWidth
            sx={textboxStyle}
            value={sewadarBadge}
          />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button fullWidth variant="contained" onClick={onSubmitClick}>
            Submit
          </Button>
        </Grid>
      </Grid>
      {/* centre: "SRE01"
clockIn: "2022-02-26T15:43:26.816"
clockOut: "2022-02-27T01:04:14.168"
department: "ss"
duration: 560 */}

      {Object.keys(details).length > 0 && (
        <Grid container>
          {Object.keys(details).map((key, i) => (
            <>
              {(details[key] || details[key] === false) &&
                key !== "sewadarAttendance" &&
                key !== "sewadarImage" &&
                key !== "sewadarBeasAttendanceList" &&
                key !== "sewadarOtherMCAttendanceList" && (
                  <>
                    <Grid
                      item
                      sm={6}
                      md={3}
                      mt={1.5}
                      style={{
                        fontWeight: 600,
                        color: primaryColor,
                      }}
                    >
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .charAt(0)
                        .toUpperCase() +
                        key.replace(/([A-Z])/g, " $1").slice(1)}
                      :
                    </Grid>
                    <Grid item sm={6} md={3} mt={1.5}>
                      {Object.keys(details[key])?.length
                        ? JSON.stringify(details[key]).replace(/['"]+/g, "")
                        : details[key].toString()}
                    </Grid>
                  </>
                )}
            </>
            // <p key={i}>
            //   {details[key] && (
            //     <>
            //       <span
            //         style={{
            //           fontWeight: 600,
            //           color: primaryColor,
            //           width: "300px",
            //           display: "inline-block",
            //         }}
            //       >
            //         {key}:
            //       </span>
            //       <span>
            //         {Object.keys(details[key])?.length
            //           ? JSON.stringify(details[key])
            //           : details[key]}
            //       </span>
            //     </>
            //   )}
            // </p>
          ))}
          {/* {key === "sewadarAttendance"&&(
            
          )} */}
        </Grid>
      )}
      {Object.keys(details).length > 0 && (
        <div style={{ maxWidth: "900px", margin: "16px auto" }}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <h3>Sewadar SRE Attendance</h3>
            {localStorage.getItem(ROLES) === "ROLE_SUPER_ADMIN" && (
              <Button
                variant="contained"
                sx={{
                  margin: "20px",
                  float: "right",
                }}
                onClick={downloadAllData}
              >
                Download
              </Button>
            )}
          </Box>
          <DataGrid
            density="compact"
            showCellRightBorder
            autoHeight
            sx={{
              borderColor: primaryColor,
              backgroundColor: ternaryColor,
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: secondaryColor,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: primaryColor,
                color: "white",
                fontWeight: "600",

                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              },
              "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
                color: primaryColor,
              },
            }}
            rows={details.sewadarAttendance}
            columns={attendanceColumns}
          />
        </div>
      )}
      {Object.keys(details).length > 0 && (
        <div style={{ maxWidth: "900px", margin: "16px auto" }}>
          <h3>Sewadar Beas Attendance</h3>
          <DataGrid
            density="compact"
            showCellRightBorder
            autoHeight
            sx={{
              borderColor: primaryColor,
              backgroundColor: ternaryColor,
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: secondaryColor,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: primaryColor,
                color: "white",
                fontWeight: "600",

                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              },
              "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
                color: primaryColor,
              },
            }}
            rows={details.sewadarBeasAttendanceList}
            columns={beasColumns}
          />
        </div>
      )}
      {Object.keys(details).length > 0 && (
        <div style={{ maxWidth: "900px", margin: "16px auto" }}>
          <h3>Sewadar Other MC Attendance</h3>
          <DataGrid
            density="compact"
            showCellRightBorder
            autoHeight
            sx={{
              borderColor: primaryColor,
              backgroundColor: ternaryColor,
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: secondaryColor,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: primaryColor,
                color: "white",
                fontWeight: "600",

                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              },
              "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
                color: primaryColor,
              },
            }}
            rows={details.sewadarOtherMCAttendanceList}
            columns={beasColumns}
          />
        </div>
      )}
    </>
  );
};

export default SewadarDetails;
