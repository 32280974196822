import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AUTHORIZATION, HOSTNAME, ROLES } from "../../common/constants";
import HorizontalNav from "../../components/HorizontalNav";
import authTokenForAPI from "../../utils/authTokenForAPI";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../utils/styleObjs";
import * as XLSX from "xlsx";

const GetAllotment = () => {
  const [sewadarList, setSewadarList] = useState([]);
  const columns = [
    { field: "name", headerName: "Name", minWidth: 150 },
    { field: "relativeName", headerName: "Relative Name", minWidth: 150 },
    { field: "gender", headerName: "Gender", minWidth: 150 },
    { field: "address", headerName: "Address", minWidth: 150 },
    { field: "city", headerName: "City", minWidth: 150 },
    { field: "contactNumber", headerName: "Contact Number", minWidth: 150 },
    { field: "numberOfPerson", headerName: "Number Of Person", minWidth: 150 },
    { field: "blockNumber", headerName: "Block Number", minWidth: 150 },
  ];
  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/accommodationAllotment`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setSewadarList(res.data);
        })
    );
  }, []);

  const navLinks = [{ key: 0, label: "Add Allotment", path: "/accomodation/allotment" }];

  const downloadAllData = () => {
    const ws = XLSX.utils.json_to_sheet(sewadarList);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    XLSX.writeFile(wb, "download.xlsx");
  };

  return (
    <>
      <HorizontalNav navLinks={navLinks} pageName="Accommodation details" />
      <Box display={"flex"} justifyContent={"space-between"}>
        <h2>Accommodation details</h2>
        {localStorage.getItem(ROLES) === "ROLE_SUPER_ADMIN" && (
          <Button
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
            }}
            onClick={downloadAllData}
          >
            Download
          </Button>
        )}
      </Box>
      <div style={{ height: "70vh" }}>
        <DataGrid
          density="compact"
          showCellRightBorder
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          rows={sewadarList}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={(e) => console.log(e)}
        />
      </div>
    </>
  );
};

export default GetAllotment;
