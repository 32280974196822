import { Alert, Button, Grid, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AUTHORIZATION, HOSTNAME_PATHI } from "../../common/constants";
import GenericForm from "../../components/GenericForm";
import HorizontalNav from "../../components/HorizontalNav";
import authTokenForAPI from "../../utils/authTokenForAPI";

const reportDateOps = (fieldDateState) => {
  return [
    {
      key: "from",
      type: "datepicker",
      label: "From",
      value: fieldDateState.from,
    },
    {
      key: "to",
      type: "datepicker",
      label: "To",
      value: fieldDateState.to,
    },
  ];
};

const reportOps = (reportState) => {
  return [
    {
      key: "reportType",
      type: "dropdown",
      label: "Report Type",
      value: reportState.reportType,
      options: [
        { id: 1, value: "SK_SR", label: "SATSANG KARTA/READER" },
        { id: 2, value: "Pathi", label: "PATHIS" },
        { id: 3, value: "Centre", label: "CENTRES" },
      ],
    },
    {
      key: "consolidated",
      type: "dropdown",
      label: "Grouping",
      value: reportState.consolidated,
      options: [
        { id: 1, value: "Consolidated", label: "Consolidated" },
        { id: 2, value: "Non-Consolidated", label: "Non-Consolidated" },
      ],
    },
    // {
    //   key: "includeOutstation",
    //   type: "dropdown",
    //   label: "Outstation Duties",
    //   value: reportState.includeOutstation,
    //   options: [
    //     { id: 1, value: "Include Outstation", label: "Include Outstation" },
    //     { id: 2, value: "Don't include", label: "Don't include" },
    //   ],
    // },
  ];
};

const ScheduleReport = () => {
  const [dateState, setDateState] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const [reportState, setReportState] = useState({});
  const onDateFieldChange = (key, val) => {
    setDateState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const onFieldChange = (key, val) => {
    setReportState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  const onSubmit = () => {
    trackPromise(
      axios
        .get(
          `${HOSTNAME_PATHI}/reports/schedule/${moment(dateState.from).format(
            "YYYY-MM-DD"
          )}/${moment(dateState.to).format("YYYY-MM-DD")}?reportType=${
            reportState.reportType
          }&responseType=PDF&consolidated=${
            reportState.consolidated === "Consolidated"
          }`,
          {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
            responseType: "blob",
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${moment(dateState.from).format("YYYY-MM-DD")}_${moment(
              dateState.to
            ).format("YYYY-MM-DD")}_${reportState.reportType}${
              reportState.consolidated
            }.pdf`
          );
          document.body.appendChild(link);
          link.click();
        })
    );
  };

  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Generate Report" />
      <h2>Generate Report</h2>
      <Box>
        <GenericForm
          fieldOptions={reportDateOps(dateState)}
          onFieldChange={onDateFieldChange}
        />
        <br />
        <GenericForm
          fieldOptions={reportOps(reportState)}
          onFieldChange={onFieldChange}
        />
      </Box>
      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{ margin: "20px", float: "right" }}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ScheduleReport;
