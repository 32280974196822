import { Alert, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useLocation } from "react-router-dom";
import {
  AUTHORIZATION,
  DEPARTMENT,
  HOSTNAME,
  ROLES,
} from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";

const LiveCountDept = () => {
  const [liveCount, setLiveCount] = useState([]);
  const [dept, setDept] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  const locationParams = useLocation();

  useEffect(() => {
    let department = "";
    if (localStorage.getItem(ROLES) === "ROLE_INCHARGE") {
      department = localStorage.getItem(DEPARTMENT);
    } else {
      department = locationParams?.state?.department;
    }
    setDept(department);
    trackPromise(
      axios
        .get(
          `${HOSTNAME}/sewadarAttendances/liveCountDept?department=${department}`,
          {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setLiveCount(res.data);
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Live Sewadar Count cannot be fetched.",
            });
          }
        })
        .catch(() => {
          setSnack({
            open: true,
            severity: "error",
            msg: "Live Sewadar Count cannot be fetched.",
          });
        })
    );
  }, []);

  const columns = [
    { field: "id", headerName: "Sewadar ID", width: 150 },
    { field: "name", headerName: "Sewadar name", width: 200 },
  ];

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Department Live Count" />
      <h2>Department-wise Live Sewadar Count</h2>
      <Box my={2} fontSize="20px">
        <div style={{margin: '8px 0'}}>
          Department :{" "}
          <u>
            {dept ? <b>{dept}</b>: <em>Not Specified</em>}
          </u>
        </div>
        Total Count : {liveCount.length}
      </Box>
      <div style={{ maxWidth: "350px" }}>
        <DataGrid
          density="compact"
          showCellRightBorder
          autoHeight
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          rows={liveCount}
          columns={columns}
        />
      </div>
    </>
  );
};

export default LiveCountDept;
