import { Alert, Button, Grid, Snackbar } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AUTHORIZATION, HOSTNAME_PATHI } from "../../../common/constants";
import GenericForm from "../../../components/GenericForm";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";

const ConfigureShabad = () => {
  const [editFlow, setEditFlow] = useState(false);
  const [data, setData] = useState({});
  const [mediaOps, setMediaOps] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const params = useParams();
  const history = useNavigate();
  useEffect(() => {
    if (window.location.pathname.includes("/edit/")) {
      setEditFlow(true);
    }
  }, []);
  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/shabadMedias`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);
          setMediaOps(
            res.data.map((v) => ({
              ...v,
              id: v.ref,
              label: v.shabadMediaGroup,
              value: v.ref,
            }))
          );
        })
    );
  }, []);
  useEffect(() => {
    if (editFlow) {
      trackPromise(
        axios
          .get(`${HOSTNAME_PATHI}/shabads/${params.id}`, {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          })
          .then((res) => {
            setData({ ...res.data });
          })
      );
    }
  }, [params.id, editFlow]);

  const onFieldChange = (key, val) => {
    setData((prevState) => {
      return { ...prevState, [key]: val };
    });
  };
  const navLinks = [
    { key: 0, label: "Home", path: "/home" },
    { key: 1, label: "Shabad", path: "/shabad" },
  ];

  const fieldOptions = [
    {
      key: "ref",
      type: "textbox",
      label: "ID",
      value: data.ref,
      disabled: true,
      focused: editFlow,
    },
    {
      key: "shabadMediaRef",
      type: "dropdown",
      label: "Media Type",
      value: data.shabadMediaRef,
      options: mediaOps,
      focused: editFlow,
    },
    {
      key: "shabad",
      type: "textbox",
      label: "Shabad Name",
      value: data.shabad,
      focused: editFlow,
    },
    {
      key: "satsandNumber",
      type: "textbox",
      label: "Satsang Number",
      value: data.satsandNumber,
      focused: editFlow,
    },
    {
      key: "bani",
      type: "textbox",
      label: "Bani",
      value: data.bani,
      focused: editFlow,
    },
    {
      key: "book",
      type: "textbox",
      label: "Book",
      value: data.book,
      focused: editFlow,
    },
    {
      key: "page",
      type: "number",
      label: "Page",
      value: data.page,
      focused: editFlow,
    },
    {
      key: "duration",
      type: "number",
      label: "Duration",
      value: data.duration,
      focused: editFlow,
    },
  ];

  const saveData = () => {
    const authToken = authTokenForAPI();
    if (editFlow) {
      trackPromise(
        axios
          .put(`${HOSTNAME_PATHI}/shabads/${params.id}`, data, {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          })
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Shabad added successfully",
              });
              history("/shabad");
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Shabad cannot be added, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Shabad cannot be added, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    } else {
      trackPromise(
        axios
          .post(`${HOSTNAME_PATHI}/shabads`, data, {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          })
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Shabad added successfully",
              });
              history("/shabad");
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Shabad cannot be added, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Shabad cannot be added, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    }
  };
  const configConstant = editFlow ? "Edit" : "Add";
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav
        navLinks={navLinks}
        pageName={`${configConstant} Shabad`}
      />
      <h2>{configConstant} Shabad</h2>
      <GenericForm
        fieldOptions={editFlow ? fieldOptions : fieldOptions.slice(1)}
        onFieldChange={onFieldChange}
      />
      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
            }}
            onClick={saveData}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigureShabad;
