import { CENTRE } from "../../../common/constants";

export const reportDateOps = (fieldDateState) => {
  return [
    {
      key: "from",
      type: "datepicker",
      label: "From",
      value: fieldDateState.from,
    },
    {
      key: "to",
      type: "datepicker",
      label: "To",
      value: fieldDateState.to,
    },
  ];
};

export const reportOtherOps = (fieldState, centres, subcentres, department) => {
  return [
    {
      key: "centre",
      type: "dropdown",
      label: "Centre",
      value:fieldState.centre,
      options: centres,
      disabled: localStorage.getItem(CENTRE) !== "SAHARANPUR-I",
    },
    {
      key: "subCentre",
      type: "dropdown",
      label: "Sub Centre",
      value: fieldState.subCentre,
      options: subcentres,
    },
    {
      key: "department",
      type: "dropdown",
      label: "Department",
      value: fieldState.department,
      options: department,
    },
    {
      key: "gender",
      type: "dropdown",
      label: "Gender",
      value: fieldState.gender,
      options: [
        {
          key: "",
          label: (() => <em>None</em>)(),
          value: "",
        },
        {
          key: "M",
          label: "Male",
          value: "M",
        },
        {
          key: "F",
          label: "Female",
          value: "F",
        },
      ],
    },
  ];
};
