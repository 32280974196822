import "./BaseLayout.css";
import LogoIcon from "./../../static/rssb-logo.png";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import axios from "axios";
import { AUTHORIZATION, HOSTNAME } from "../../common/constants";
import authTokenForAPI from "../../utils/authTokenForAPI";
import { isAPISuccess } from "../../utils/commonUtils";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { Box } from "@mui/system";

const BaseLayout = ({ children }) => {
  const history = useNavigate();
  const { promiseInProgress } = usePromiseTracker();

  const authToken = localStorage.getItem("Authorization");
  useEffect(() => {
    if (!authToken || authToken === "undefined") {
      history("/");
    }
  }, []);
  const logoutUser = () => {
    trackPromise(
      axios.get(`${HOSTNAME}/signout`, {
        headers: { [AUTHORIZATION]: authTokenForAPI() },
      })
    );
    localStorage.clear();
    history("/");
  };

  return (
    <>
      {promiseInProgress && (
        <Box
          sx={{
            display: "flex",
            zIndex: "1000",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <div className="app_header">
        <div style={{ alignItems: "center", display: "flex" }}>
          <div
            onClick={() => {
              history("/home");
            }}
          >
            <img src={LogoIcon} alt="logo" className="rssb-logo" />
          </div>
          <div className="app_heading">Sewadar Management System</div>
        </div>
        {!(!authToken || authToken === "undefined") && (
          <div>
            <Button
              variant="contained"
              style={{ border: "1px solid white", margin: "10px" }}
              onClick={logoutUser}
            >
              LOGOUT
            </Button>
          </div>
        )}
      </div>
      <div style={{ padding: "0 20px" }}>{children}</div>
    </>
  );
};

export default BaseLayout;
