import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useRef } from "react";
import { textboxStyle } from "../../utils/styleObjs";

const AfterScan = ({ res, setRes, clockIn, clockOut, allBtnDisabled }) => {
  const inputRef = useRef(null);
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Box
            zIndex={1}
            height={"75px"}
            width={"400px"}
            position={"absolute"}
          ></Box>
          <Box position={"relative"}>
            <TextField
              ref={inputRef}
              placeholder="Sewadar Badge Number"
              onChange={(e) => {
                console.log(e.target.value);
                return setRes(e.target.value);
              }}
              fullWidth
              sx={textboxStyle}
              value={res}
              inputProps={{ inputMode: "numeric" }}
              autoFocus
              // onKeyDown={(e)=>e.preventDefault}
              // onBlur={(e) => {
              //   e.target.focus();
              // }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        height={150}
        mt={2}
      >
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={clockIn}
            disabled={allBtnDisabled}
          >
            Clock in
          </Button>
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={clockOut}
            disabled={allBtnDisabled}
          >
            Clock out
          </Button>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" m={2}>
        <Button
          variant="contained"
          size="large"
          onClick={() => window.location.reload()}
        >
          Re-Scan
        </Button>
      </Box>
    </>
  );
};

export default AfterScan;
