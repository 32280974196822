// import { MultiFormatReader, BarcodeFormat, DecodeHintType, RGBLuminanceSource, BinaryBitmap, HybridBinarizer } from '@zxing/library';

// import { BrowserBarcodeReader, BrowserCodeReader } from "@zxing/library";
import { BrowserCodeReader, BrowserMultiFormatReader } from "@zxing/library";
import { useEffect } from "react";

// const hints = new Map();
// const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX/*, ...*/];

// hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

// const reader = new MultiFormatReader();

// reader.setHints(hints);

// const luminanceSource = new RGBLuminanceSource(imgByteArray, imgWidth, imgHeight);
// const binaryBitmap = new BinaryBitmap(new HybridBinarizer(luminanceSource));

// reader.decode(binaryBitmap);

const ZXingScanner = () => {
  useEffect(() => {
    let selectedDeviceId;
    const codeReader = new BrowserMultiFormatReader();
    console.log("ZXing code reader initialized");
    // const dev = codeReader.videoInputDevices;

    codeReader
      //   .decodeFromVideoDevice(null, null, (e) => {
      //     console.log(e);
      //   })
      .getVideoInputDevices()
      .then((videoInputDevices) => {
        const sourceSelect = document.getElementById("sourceSelect");
        selectedDeviceId = videoInputDevices[0].deviceId;
        if (videoInputDevices.length > 1) {
          videoInputDevices.forEach((element) => {
            const sourceOption = document.createElement("option");
            sourceOption.text = element.label;
            sourceOption.value = element.deviceId;
            sourceSelect.appendChild(sourceOption);
          });

          sourceSelect.onchange = () => {
            selectedDeviceId = sourceSelect.value;
          };

          const sourceSelectPanel =
            document.getElementById("sourceSelectPanel");
          sourceSelectPanel.style.display = "block";
        }

        document.getElementById("startButton").addEventListener("click", () => {
          codeReader
            .decodeOnceFromVideoDevice(selectedDeviceId, "video")
            .then((result) => {
              console.log(result);
              document.getElementById("result").textContent = result.text;
            })
            .catch((err) => {
              console.error(err);
              document.getElementById("result").textContent = err;
            });
          console.log(
            `Started continous decode from camera with id ${selectedDeviceId}`
          );
        });

        // document.getElementById("resetButton").addEventListener("click", () => {
        //   document.getElementById("result").textContent = "";
        //   codeReader.reset();
        //   console.log("Reset.");
        // });
      })
      .catch((err) => {
        console.error(err);
      });
  });
  return (
    <>
      <div>
        <video
          id="video"
          width="600"
          height="400"
          style={{ border: "1px solid gray" }}
        ></video>
      </div>
      <div>
        <a class="button" id="startButton">
          Start
        </a>
        <a class="button" id="resetButton">
          Reset
        </a>
      </div>
      <div id="sourceSelectPanel" style={{ display: "none" }}>
        <label for="sourceSelect">Change video source:</label>
        <select id="sourceSelect"></select>
      </div>
      <label>Result:</label>
      <pre>
        <code id="result"></code>
      </pre>
    </>
  );
};
export default ZXingScanner;
