import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { HOSTNAME } from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import { textboxStyle } from "../../../utils/styleObjs";

const AddSewadarBulk = () => {
  const [sewadarFile, setSewadarFile] = useState("");

  const onFieldChange = (val) => {
    setSewadarFile(val);
  };

  const onSubmitClick = () => {
    // axios.get(`${HOSTNAME}/sewadars/${sewadarBadge}`).then((res) => {
    //   console.log(res);
    //   history(`/editSewadar/${sewadarBadge}`, { state: res.data });
    // setCentresList(res.data);
    console.log(sewadarFile);
  };
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];
  return (
    <>
      <HorizontalNav navLinks={navLinks} pageName="Add Sewadar in Bulk" />
      <h2>Add Sewadar in Bulk</h2>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            placeholder="Sewadar Badge Number"
            onChange={(e) => onFieldChange(e.target.value)}
            fullWidth
            sx={textboxStyle}
            value={sewadarFile}
            type="file"
          />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button fullWidth variant="contained" onClick={onSubmitClick}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddSewadarBulk;
