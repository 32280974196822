import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router";
import {
  AUTHORIZATION,
  HOSTNAME,
  ROLES,
  DEPARTMENT,
  CENTRE,
} from "../../common/constants";
import { textboxStyle } from "../../utils/styleObjs";

const Login = () => {
  const [userCreds, setUserCreds] = useState({});
  const history = useNavigate();

  const onFieldChange = (val, key) => {
    setUserCreds({ ...userCreds, [key]: val });
  };
  const onLoginClick = () => {
    trackPromise(
      axios
        .post(`${HOSTNAME}/signin`, {
          ...userCreds,
        })
        .then(({ data }) => {
          history(`/home`);
          localStorage.setItem(AUTHORIZATION, data.jwt);
          localStorage.setItem(ROLES, data.roles);
          localStorage.setItem(DEPARTMENT, data.department);
          if (!!data.centre) localStorage.setItem(CENTRE, data.centre);
        })
    );
  };

  useEffect(() => {
    const authToken = localStorage.getItem("Authorization");
    if (authToken && authToken !== "undefined") {
      history("/home");
    }
  }, []);
  return (
    <>
      <h2>Login</h2>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        direction="column"
      >
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            placeholder="Username"
            onChange={(e) => onFieldChange(e.target.value, "username")}
            fullWidth
            sx={{ ...textboxStyle, width: "300px" }}
            value={userCreds.username}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            placeholder="Password"
            onChange={(e) => onFieldChange(e.target.value, "password")}
            fullWidth
            sx={{ ...textboxStyle, width: "300px" }}
            value={userCreds.password}
            type="password"
          />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            onClick={onLoginClick}
            sx={{ width: "200px" }}
            size="large"
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
