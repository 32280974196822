import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { primaryColor } from "../utils/styleObjs";

const ConfirmationModal = (props) => {
  const { title, content, returnValue, showConfirmation, yesButton, noButton } =
    props;

  return (
    <>
      <Dialog
        open={showConfirmation}
        maxWidth="xs"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => returnValue(false)}
            sx={{
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: 600,
              marginRight: "20px",
              ":hover": {
                backgroundColor: "#8b0000cf",
              },
            }}
          >
            {noButton}
          </Button>
          <Button
            onClick={() => returnValue(true)}
            sx={{
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: 600,
              marginRight: "20px",
              ":hover": {
                backgroundColor: "#8b0000cf",
              },
            }}
          >
            {yesButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  returnValue: PropTypes.func.isRequired,
  showConfirmation: PropTypes.bool.isRequired,
  yesButton: PropTypes.string,
  noButton: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  title: "Confirmation",
  content: "",
  yesButton: "Yes",
  noButton: "No",
};

export default ConfirmationModal;
