import {
  Alert,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Switch,
} from "@mui/material";

import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useLocation, useNavigate, useParams } from "react-router";
import { AUTHORIZATION, HOSTNAME } from "../../../common/constants";
import GenericForm from "../../../components/GenericForm";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";
import { primaryColor, secondaryColor } from "../../../utils/styleObjs";
import { fieldOptions } from "../AddSewadar/addSewadarUtils";

const EditSewadarForm = () => {
  const [sewadarInfo, setSewadarInfo] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [centres, setCentres] = useState([]);
  const [subcentres, setSubcentres] = useState([]);
  const [department, setDepartment] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const params = useParams();
  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    setSewadarInfo(location?.state);
    setIsActive(location?.state?.isActive);
  }, []);

  const onFieldChange = (key, val) => {
    setSewadarInfo((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const updateSewadarData = () => {
    sewadarInfo.birthDate = moment(sewadarInfo.birthDate).format("YYYY-MM-DD");
    sewadarInfo.initiationDate = moment(sewadarInfo.initiationDate).format(
      "YYYY-MM-DD"
    );
    const authToken = authTokenForAPI();
    trackPromise(
      axios
        .put(
          `${HOSTNAME}/sewadars/${params.sewadarId}`,
          {
            ...sewadarInfo,
          },
          {
            headers: { [AUTHORIZATION]: authToken },
          }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: "Sewadar details updated successfully",
            });
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Sewadar Details cannot be updated, please try again.",
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Sewadar Details cannot be updated, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
    if (sewadarInfo.isActive !== isActive) {
      trackPromise(
        axios
          .put(
            `${HOSTNAME}/sewadars/inactive/${params.sewadarId}`,
            {
              sewadarStatus: isActive,
              remarks: sewadarInfo?.remarks,
            },
            {
              headers: { [AUTHORIZATION]: authToken },
            }
          )
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Sewadar status updated successfully",
              });
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Sewadar Status cannot be updated, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Sewadar Status cannot be updated, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    }
  };
  const navLinks = [
    { key: 0, label: "Home", path: "/home" },
    { key: 1, label: "Edit Sewadar", path: "/editSewadar" },
  ];

  const arrayData = (data) => {
    const arr = [];
    data.forEach((val) => {
      arr.push({
        key: val.name,
        label: val.name,
        value: val.name,
      });
    });
    return arr;
  };

  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setCentres(arrayData(res.data));
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME}/sub-centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setSubcentres(arrayData(res.data));
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME}/departments`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setDepartment(arrayData(res.data));
        })
    );
  }, []);
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName={`ID:${params.sewadarId}`} />
      <h2>Edit Sewadar</h2>
      <GenericForm
        fieldOptions={fieldOptions(
          sewadarInfo,
          centres,
          subcentres,
          department
        )}
        onFieldChange={onFieldChange}
      />
      <br />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
              color="error"
              style={{
                color: primaryColor,
              }}
            />
          }
          label={<b>Active Sewadar</b>}
        />
      </FormGroup>
      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{ margin: "20px", float: "right" }}
            onClick={updateSewadarData}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EditSewadarForm;
