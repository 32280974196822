import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router";
import { AUTHORIZATION, HOSTNAME } from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";
import { textboxStyle } from "../../../utils/styleObjs";

const EditSewadarSearch = () => {
  const [sewadarBadge, setSewadarBadge] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const history = useNavigate();

  const onFieldChange = (val) => {
    setSewadarBadge(val);
  };

  const onSubmitClick = () => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/sewadars/${sewadarBadge}`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          if (!isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "error",
              msg: "Sewadar ID not valid, please try with a valid ID.",
            });
          } else {
            console.log(res);
            history(`/editSewadar/${sewadarBadge}`, { state: res.data });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Sewadar ID not valid, please try with a valid ID.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Edit Sewadar" />
      <h2>Edit Sewadar</h2>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <TextField
            placeholder="Sewadar Badge Number"
            onChange={(e) => onFieldChange(e.target.value)}
            fullWidth
            sx={textboxStyle}
            value={sewadarBadge}
          />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button fullWidth variant="contained" onClick={onSubmitClick}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EditSewadarSearch;
