import { Alert, Button, Grid, Snackbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";
import { AUTHORIZATION, days, HOSTNAME_PATHI } from "../../common/constants";
import GenericForm from "../../components/GenericForm";
import HorizontalNav from "../../components/HorizontalNav";
import authTokenForAPI from "../../utils/authTokenForAPI";
import { getSatsangTime, isAPISuccess } from "../../utils/commonUtils";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../utils/styleObjs";
import ConfirmationModal from "../../components/ConfirmationModal";

const SatsangScheduleDateWise = () => {
  const history = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [delId, setDelId] = useState();
  const [data, setData] = useState({});
  const [centreData, setCentreData] = useState([]);
  const [listShabadMedia, setListShabadMedia] = useState([]);
  const [listShabad, setListShabad] = useState([]);
  const [listSchedule, setListSchedule] = useState({});
  const [listLedger, setListLedger] = useState([]);
  const [listPathi, setListPathi] = useState([]);
  const [listSKSR, setListSKSR] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  const [centreOps, setCentreOps] = useState([]);
  const [designationOps, setDesignationOps] = useState([]);

  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);
          setCentreData(res.data);
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/designations`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);
          setDesignationOps(
            res.data.map((v) => ({
              ...v,
              id: v.ref,
              label: v.designation,
              value: v.ref,
            }))
          );
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/shabadMedias`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setListShabadMedia(
            res.data.map((v) => ({
              ...v,
              id: v.ref,
              label: v.shabadMediaGroup,
              value: v.ref,
            }))
          );
        })
    );

    trackPromise(
      axios
        .get(
          `${HOSTNAME_PATHI}/reports/schedule/${moment(data.date).format(
            "YYYY-MM-DD"
          )}/${moment(data.date).format(
            "YYYY-MM-DD"
          )}?reportType=Centre&responseType=json&consolidated=true`,
          {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          }
        )
        .then((res) => {
          setListSchedule(massageListSchedule(res.data));
        })
    );
    setRefresh(false);
  }, [data.date, refresh === true]);

  const massageListSchedule = (data) => {
    var consolidatedList = [];
    for (const val in data) {
      data[val][0].id = data[val]?.[0].ref;
      consolidatedList = [...consolidatedList, ...data[val]];
    }
    return consolidatedList;
  };

  useEffect(() => {
    trackPromise(
      axios
        // TODO: get Shabads based on shabadMedia (api not found in BE repo)
        .get(`${HOSTNAME_PATHI}/shabads`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);
          setListShabad(
            res.data.map((v) => ({
              ...v,
              id: v.ref,
              label: v.shabad,
              value: v.ref,
            }))
          );
        })
    );

    trackPromise(
      axios
        // TODO: get Ledgers based on shabadMedia (api not found in BE repo)
        .get(`${HOSTNAME_PATHI}/ledgers`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setListLedger(
            res.data.map((v) => ({
              ...v,
              id: v.ref,
              label: v.name,
              value: v.ref,
            }))
          );
        })
    );
  }, [data.type]);

  useEffect(() => {
    const pathiRef = designationOps.find(
      (v) => v.designation.toLowerCase() === "pathi"
    )?.ref;
    setListPathi(listLedger.filter((v) => v.designationRef === pathiRef));
    setListSKSR(listLedger.filter((v) => v.designationRef !== pathiRef));
  }, [designationOps, listLedger]);

  useEffect(() => {
    const day = moment(data.date).format("dddd").toUpperCase();
    const filterCentres = centreData.filter((v) => v.satsangDays.includes(day));
    history({ search: `?date=${moment(data.date).format("DD-MM-YYYY")}` });

    setCentreOps(
      filterCentres.map((v) => ({
        ...v,
        id: v.code,
        midWeek: v.satsangDays?.find((e) => e !== "SUNDAY"),
        label: v.centreName,
        value: v.code,
      }))
    );
  }, [centreData, data.date]);

  const onFieldChange = (key, val) => {
    console.log(key, val);
    setData((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const clearData = () => {
    setData({ date: data.date });
  };
  const massageData = (data) => {
    const dataObj = {
      dateValue: moment(data.date).format("YYYY-MM-DD"),
      // TODO: HardCode Time value according to quarter and midweek
      timeValue: getSatsangTime(data.date),
      centreCode: data.centre,
      shabadRef: data.shabad,
    };
    if (data.sk_sr && data.pathi) {
      dataObj.ledgerRefList = [
        {
          ledgerRef: data.sk_sr,
        },
        {
          ledgerRef: data.pathi,
        },
      ];
    } else if (data.sk_sr) {
      dataObj.ledgerRefList = [
        {
          ledgerRef: data.sk_sr,
        },
      ];
    }
    // {
    //     "centre": 1,
    //     "date": "2022-09-12T17:39:23.000Z",
    //     "type": 3,
    //     "shabad": 1,
    //     "sk_sr": 1,
    //     "pathi": 1
    // }

    console.log(data);
    return dataObj;
  };

  const onSubmit = () => {
    trackPromise(
      axios
        .post(`${HOSTNAME_PATHI}/schedules`, massageData(data), {
          headers: {
            [AUTHORIZATION]: authTokenForAPI(),
          },
        })
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: "Schedule added successfully",
            });
            clearData();
            setRefresh(true);
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Schedule cannot be added, please try again.",
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Schedule cannot be added, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  const getDesRefForShabadMedRef = () => {
    return designationOps.find(
      (v) =>
        v.designation.toLowerCase() ===
        listShabadMedia
          .find((e) => e.ref === data.type)
          ?.shabadMediaGroup?.toLowerCase()
    )?.ref;
  };

  const columns = [
    { field: "ref", headerName: "REF", width: 150 },
    { field: "cname", headerName: "Centre name", width: 250 },
    { field: "satsangDate", headerName: "Date", width: 150 },
    { field: "satsangDay", headerName: "Day", width: 150 },
    { field: "satsangType", headerName: "Satsang Type", width: 150 },
    { field: "satsangKarta", headerName: "Satsang Karta", width: 150 },
    { field: "pathi", headerName: "Pathi", width: 150 },
    { field: "shabad", headerName: "Shabad", width: 250 },
    { field: "book", headerName: "Book", width: 150 },
    { field: "page", headerName: "Page", width: 150 },
    { field: "satsangTime", headerName: "Satsang Time", width: 150 },
    {
      field: "action",
      headerName: "Delete",
      renderCell: (params) => (
        <DeleteIcon
          sx={{ color: primaryColor, alignContent: "center" }}
          onClick={(e) => {
            e.preventDefault();
            deleteAction(params?.row?.ref);
          }}
        />
      ),
    },
  ];

  const deleteAction = (id) => {
    setOpenModal(true);
    setDelId(id);
  };

  const confirmationModal = (returnVal) => {
    setOpenModal(false);
    if (returnVal) {
      deleteSchedule();
    } else {
      setDelId("");
    }
  };

  const deleteSchedule = () => {
    trackPromise(
      axios
        .delete(`${HOSTNAME_PATHI}/schedules/${delId}`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: "Schedule deleted successfully",
            });
            setRefresh(true);
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Schedule cannot be deleted",
            });
          }
          setDelId("");
        })
    );
  };

  return (
    <>
      <ConfirmationModal
        title="Delete Schedule"
        content="Are you sure you want to delete the schedule?"
        returnValue={confirmationModal}
        showConfirmation={openModal}
        yesButton="Yes"
        noButton="No"
      />
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName={`Satsang Schedule`} />
      <h2>Satsang Schedule</h2>
      <GenericForm
        fieldOptions={[
          {
            key: "date",
            type: "datepicker",
            label: "Date",
            value: data.date,
            defaultValue: null,
            // shouldDisableDate: (d) =>
            //   d.getDay() !== 0 &&
            //   days[d.getDay()].toUpperCase() !==
            //     centreOps
            //       .find((d) => d.id === data.centre)
            //       .midWeek.toUpperCase(),
          },
        ]}
        onFieldChange={onFieldChange}
      />

      <br />
      {data.date && (
        <GenericForm
          fieldOptions={[
            {
              key: "centre",
              type: "dropdown",
              label: "Centre",
              value: data.centre,
              options: centreOps,
            },
          ]}
          onFieldChange={onFieldChange}
        />
      )}
      <br />
      {data.centre && data.date && (
        <GenericForm
          fieldOptions={[
            {
              key: "type",
              type: "dropdown",
              label: "Type of Satsang",
              value: data.type,
              options: listShabadMedia,
            },
          ]}
          onFieldChange={onFieldChange}
        />
      )}
      <br />
      {data.type &&
        !(
          listShabadMedia
            .find((med) => med.ref === data.type)
            .shabadMediaGroup.toLowerCase() === "audio" ||
          listShabadMedia
            .find((med) => med.ref === data.type)
            .shabadMediaGroup.toLowerCase() === "video"
        ) && (
          <GenericForm
            fieldOptions={[
              {
                key: "sk_sr",
                type: "dropdown",
                label: "Satsang Preacher",
                value: data.sk_sr,
                options: listSKSR.filter(
                  (v) => v.designationRef === getDesRefForShabadMedRef()
                ),
              },
              {
                key: "pathi",
                type: "dropdown",
                label: "Pathi",
                value: data.pathi,
                options: listPathi,
              },
            ]}
            onFieldChange={onFieldChange}
          />
        )}
      <br />

      {data.type && (
        <GenericForm
          fieldOptions={[
            {
              key: "shabad",
              type: "dropdown",
              label: "Shabad",
              value: data.shabad,
              options: listShabad?.filter(
                (v) => v.shabadMediaRef === data.type
              ),
            },
          ]}
          onFieldChange={onFieldChange}
        />
      )}

      {data.shabad && (
        <Grid container justifyContent="center">
          <Grid item lg={2} md={3} sm={4} xs={8}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                margin: "20px",
                float: "right",
              }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}

      <br />
      <DataGrid
        density="compact"
        showCellRightBorder
        autoHeight
        sx={{
          borderColor: primaryColor,
          backgroundColor: ternaryColor,
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: secondaryColor,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: primaryColor,
            color: "white",
            fontWeight: "600",

            "& .MuiSvgIcon-root": {
              color: "white",
            },
          },
          "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
            color: primaryColor,
          },
        }}
        rows={Object.values(listSchedule)}
        columns={columns}
      />
      <br />
    </>
  );
};

export default SatsangScheduleDateWise;
