import { Alert, Button, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";
import { AUTHORIZATION, HOSTNAME_PATHI } from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";

const ListCentre = () => {
  const [listCentre, setListCentre] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const history = useNavigate();
  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);

          setListCentre(
            res.data.map((v) => ({
              ...v,
              id: v.code,
              midWeek: v.satsangDays?.find((e) => e !== "SUNDAY"),
            }))
          );
        })
    );
  }, []);
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  const columns = [
    { field: "code", headerName: "ID" },
    { field: "centreName", headerName: "Satsang Place", width: 150 },
    { field: "attachedTo", headerName: "Attached To", width: 150 },
    { field: "address1", headerName: "Address 1", width: 150 },
    { field: "address2", headerName: "Address 2", width: 150 },
    { field: "stdCode", headerName: "STD Code", width: 150 },
    { field: "telephoneNumber", headerName: "Telephone Number", width: 150 },
    { field: "midWeek", headerName: "Mid Week Satsang Day", width: 150 },
  ];

  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Centres" />
      <Box display={"flex"} justifyContent={"space-between"}>
        <h2>Centres</h2>
        <Button
          variant="contained"
          sx={{
            margin: "20px",
            float: "right",
          }}
          onClick={() => history("/centre/add")}
        >
          Add Centre
        </Button>
      </Box>
      <div>
        <DataGrid
          density="compact"
          showCellRightBorder
          autoHeight
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          onRowClick={(v) => {
            console.log(v);
            history(`/centre/edit/${v.id}`);
          }}
          rows={listCentre}
          columns={columns}
        />
      </div>
    </>
  );
};
export default ListCentre;
