import { Button, Divider, Grid } from "@mui/material";
import { homepageBtn, homeIconStyle } from "../utils/styleObjs";
import GroupsIcon from "@mui/icons-material/Groups";
import AlbumIcon from "@mui/icons-material/Album";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SaveIcon from "@mui/icons-material/Save";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useNavigate } from "react-router";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import { CENTRE, DEPARTMENT, ROLES } from "../common/constants";
import { useEffect, useState } from "react";
import CentreInfo from "../components/CentreInfo";

const centreList = [
  "SAHARANPUR-I",
  "SAHARANPUR-II",
  "SAHARANPUR-III",
  "SAHARANPUR-IV",
  "GANGOH",
  "NAKUR",
  "CHHUTMALPUR",
  "GURUNANAKPURA",
  "ISLAMNAGAR",
];

const HomePageBtn = (props) => (
  <Button variant="contained" sx={homepageBtn} {...props}>
    {props.children}
  </Button>
);
const HomePage = () => {
  const [admin, setAdmin] = useState(false);
  const [incharge, setIncharge] = useState(false);
  const [secretary, setSecretary] = useState(false);
  const history = useNavigate();
  const roles = localStorage.getItem(ROLES);
  useEffect(() => {
    if (roles === "ROLE_SUPER_ADMIN") {
      setAdmin(true);
    }
    if (roles === "ROLE_INCHARGE") {
      setIncharge(true);
    }
    if (roles === "ROLE_SECRETARY") {
      setSecretary(true);
    }
  }, []);
  if (roles === "ROLE_SCHEDULER_ADMIN") {
    return (
      <>
        <>
          <h2>Master</h2>
          <Grid container spacing={10}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/shabadMedia");
                }}
              >
                <SaveIcon style={homeIconStyle} />
                Media
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/ledger");
                }}
              >
                <GroupsIcon style={homeIconStyle} />
                Ledger
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/centre");
                }}
              >
                <PinDropIcon style={homeIconStyle} />
                Satsang Place
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/designation");
                }}
              >
                <LocalPoliceIcon style={homeIconStyle} />
                Designation
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/shabad");
                }}
              >
                <AlbumIcon style={homeIconStyle} />
                Shabds
              </HomePageBtn>
            </Grid>
          </Grid>
        </>

        <>
          <Divider style={{ padding: "10px" }} />
          <h2>Schedule</h2>
          <Grid container spacing={10}>
            {/* <Grid item lg={3} md={3} sm={6} xs={12}>
            <HomePageBtn
              onClick={() => {
                history("/schedule");
              }}
            >
              <EventNoteIcon style={homeIconStyle} />
              Satsang Schedule
            </HomePageBtn>
          </Grid> */}
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/scheduleDateWise");
                }}
              >
                <EventNoteIcon style={homeIconStyle} />
                Satsang Schedule Date Wise
              </HomePageBtn>
            </Grid>
          </Grid>
        </>

        <>
          <Divider style={{ padding: "10px" }} />
          <h2>Reports</h2>
          <Grid container spacing={10}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/scheduleReport");
                }}
              >
                <SummarizeIcon style={homeIconStyle} />
                Satsang Schedule Report
              </HomePageBtn>
            </Grid>
          </Grid>
        </>
      </>
    );
  }
  return (
    <>
      <CentreInfo />
      {!incharge && !secretary && (
        <>
          <h2>Attendance</h2>
          <Grid container spacing={10}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/markAttendance");
                }}
              >
                <EmojiPeopleIcon style={homeIconStyle} />
                Mark Attendance
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/markAttendanceManual");
                }}
              >
                <AccountBoxIcon style={homeIconStyle} />
                Mark Attendance using Scanner
              </HomePageBtn>
            </Grid>
            {admin && (
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <HomePageBtn
                  onClick={() => {
                    history("/markManualTime");
                  }}
                >
                  <AccountBoxIcon style={homeIconStyle} />
                  Upload Attendance Manually
                </HomePageBtn>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {/* {incharge && (
        <>
          <h2>
            Department:{" "}
            <span style={{ fontWeight: "400" }}>
              {localStorage.getItem(DEPARTMENT)}
            </span>
          </h2>
          <Grid container spacing={10}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/liveCountDept");
                }}
              >
                <PersonPinCircleIcon style={homeIconStyle} />
                Department Live Sewadar Count
              </HomePageBtn>
            </Grid>
          </Grid>
        </>
      )} */}
      {(admin || secretary) && (
        <>
          <Divider style={{ padding: "10px" }} />
          <h2>Sewadar</h2>
          <Grid container spacing={10}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/listSewadar");
                }}
              >
                <GroupsIcon style={homeIconStyle} />
                List of Sewadars
              </HomePageBtn>
            </Grid>
            {admin && (
              <>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <HomePageBtn
                    onClick={() => {
                      history("/addSewadar");
                    }}
                  >
                    <PersonAddAlt1Icon style={homeIconStyle} />
                    Add Sewadar
                  </HomePageBtn>
                </Grid>
                {/* <Grid item lg={3} md={3} sm={6} xs={12}>
          <HomePageBtn
            onClick={() => {
              history("/addSewadarBulk");
            }}
          >
            <GroupAddIcon style={homeIconStyle} />
            Add Sewadars in Bulk
          </HomePageBtn>
        </Grid> */}
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <HomePageBtn
                    onClick={() => {
                      history("/editSewadar");
                    }}
                  >
                    <PersonSearchIcon style={homeIconStyle} />
                    Edit Sewadar Info
                  </HomePageBtn>
                </Grid>
              </>
            )}
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/liveCount");
                }}
              >
                <PersonPinCircleIcon style={homeIconStyle} />
                Live Sewadar Count
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/sewadarDetails");
                }}
              >
                <PermContactCalendarIcon style={homeIconStyle} />
                Get Sewadar Details
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/sewadarAdditional");
                }}
              >
                <AddModeratorIcon style={homeIconStyle} />
                Sewadar Additional Details
              </HomePageBtn>
            </Grid>
          </Grid>
          <Divider style={{ padding: "10px" }} />
          <h2>Information</h2>
          <Grid container spacing={10} mb={4}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/centres");
                }}
              >
                <PinDropIcon style={homeIconStyle} />
                List of centres
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/subcentres");
                }}
              >
                <PinDropIcon style={homeIconStyle} />
                List of Satsang Places
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/departments");
                }}
              >
                <PersonPinIcon style={homeIconStyle} />
                List of departments
              </HomePageBtn>
            </Grid>
            {centreList.includes(localStorage.getItem(CENTRE)) && (
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <HomePageBtn
                  onClick={() => {
                    history("/generateReport");
                  }}
                >
                  <SummarizeIcon style={homeIconStyle} />
                  Generate Report
                </HomePageBtn>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {incharge && (
        <>
          <h2>
            Department:{" "}
            <span style={{ fontWeight: "400" }}>
              {localStorage.getItem(DEPARTMENT)}
            </span>
          </h2>
          <Grid container spacing={10}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/accomodation/allotment");
                }}
              >
                <PersonPinCircleIcon style={homeIconStyle} />
                Accomodation Allotment
              </HomePageBtn>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <HomePageBtn
                onClick={() => {
                  history("/accomodation/getAllotment");
                }}
              >
                <PersonPinCircleIcon style={homeIconStyle} />
                Get Accomodation Allotment
              </HomePageBtn>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default HomePage;
