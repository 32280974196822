import { Alert, Button, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";
import { AUTHORIZATION, HOSTNAME_PATHI } from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";

const ListLedger = () => {
  const [listLedger, setListLedger] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const [centreOps, setCentreOps] = useState([]);
  const [designationOps, setDesignationOps] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/ledgers`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);
          setListLedger(
            res.data.map((v) => ({
              ...v,
              id: v.ref,
              // designation: v.designation?.designation,
              // centre: v.centre?.centreName,
            }))
          );
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);

          setCentreOps(
            res.data.map((v) => ({
              ...v,
              id: v.code,
              midWeek: v.satsangDays?.find((e) => e !== "SUNDAY"),
              label: v.centreName,
              value: v.code,
            }))
          );
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/designations`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);
          setDesignationOps(
            res.data.map((v) => ({
              ...v,
              id: v.ref,
              label: v.designation,
              value: v.ref,
            }))
          );
        })
    );
  }, []);

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];
  const columns = [
    { field: "ref", headerName: "ID" },
    {
      field: "designation",
      headerName: "Designation",
      width: 150,
      valueGetter: (params) =>
        designationOps.find((v) => v.ref === params.row.designationRef)
          ?.designation,
    },
    { field: "name", headerName: "Name", width: 150 },
    { field: "address1", headerName: "Address 1", width: 150 },
    { field: "address2", headerName: "Address 2", width: 150 },
    { field: "stdCode", headerName: "STD code", width: 150 },
    { field: "telephoneNumber", headerName: "Contact", width: 150 },
    { field: "residence", headerName: "Residence Num", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    { field: "mobile", headerName: "Mobile Num", width: 150 },
    { field: "dateOfBirth", headerName: "DOB", width: 150 },
    { field: "dateOfInitiation", headerName: "Date of Initiation", width: 150 },
    {
      field: "letterNumberOfAppointment",
      headerName: "Letter Number Of Appointment",
      width: 150,
    },
    {
      field: "dateOfAppointment",
      headerName: "Date Of Appointment",
      width: 150,
    },
    {
      field: "centre",
      headerName: "Centre",
      width: 150,
      valueGetter: (params) =>
        centreOps.find((v) => v.code === params.row.centreCode)?.centreName,
    },
  ];

  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Ledgers" />
      <Box display={"flex"} justifyContent={"space-between"}>
        <h2>Ledgers</h2>
        <Button
          variant="contained"
          sx={{
            margin: "20px",
            float: "right",
          }}
          onClick={() => history("/ledger/add")}
        >
          Add Ledger
        </Button>
      </Box>
      <div>
        <DataGrid
          density="compact"
          showCellRightBorder
          autoHeight
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          onRowClick={(v) => {
            console.log(v);
            history(`/ledger/edit/${v.id}`);
          }}
          rows={listLedger}
          columns={columns}
        />
      </div>
    </>
  );
};
export default ListLedger;
