import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Grid, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AUTHORIZATION, HOSTNAME } from "../../../common/constants";
import GenericForm from "../../../components/GenericForm";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";
import { primaryColor } from "../../../utils/styleObjs";
import { ExpandMore } from "@mui/icons-material";

const SewadarAddtional = () => {
  const [beasState, setBeasState] = useState({});
  const [otherMCState, setOtherMCState] = useState({});
  const [beasSewadarDetails, setBeasSewadarDetails] = useState({});
  const [OtherSewadarDetails, setOtherSewadarDetails] = useState({});
  const [toggleBeasSection, setToggleBeasSection] = useState(true);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const onBeasFieldChange = (key, val) => {
    setBeasState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const onMCFieldChange = (key, val) => {
    setOtherMCState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const getSewadarDetails = (id, beasAttendance) => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/sewadars/${id}`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          if (isAPISuccess(res.status)) {
            if (beasAttendance) {
              setBeasSewadarDetails(res.data);
            }
            else {
              setOtherSewadarDetails(res.data);
            }
          } else {
            if (beasAttendance) {
              setBeasSewadarDetails({});
            }
            else {
              setOtherSewadarDetails({});
            }
            setBeasSewadarDetails({});
            setSnack({
              open: true,
              severity: "error",
              msg: "Sewadar ID not valid, please try with a valid ID.",
            });
          }
        })
        .catch(() => {
          if (beasAttendance) {
            setBeasSewadarDetails({});
          }
          else {
            setOtherSewadarDetails({});
          }
          setSnack({
            open: true,
            severity: "error",
            msg: "Sewadar ID not valid, please try with a valid ID.",
          });
        })
    );
  };
  const beasOps = [
    {
      key: "sewadarId",
      type: "textbox",
      label: "Sewadar ID",
      value: beasState.sewadarId,
      onBlur: (evt) => {
        getSewadarDetails(evt.target.value, true);
      },
    },
    {
      key: "clockInDate",
      type: "datepicker",
      label: "In Date",
      value: beasState.clockInDate,
    },
    {
      key: "clockOutDate",
      type: "datepicker",
      label: "Out Date",
      value: beasState.clockOutDate,
    },
  ];

  const otherMCOps = [
    {
      key: "sewadarId",
      type: "textbox",
      label: "Sewadar ID",
      value: otherMCState.sewadarId,
      onBlur: (evt) => {
        getSewadarDetails(evt.target.value, false);
      },
    },
    {
      key: "centre",
      type: "textbox",
      label: "Name of Major centre",
      value: otherMCState.centre,
    },
    {
      key: "clockInDate",
      type: "datepicker",
      label: "In Date",
      value: otherMCState.clockInDate,
    },
    {
      key: "clockOutDate",
      type: "datepicker",
      label: "Out Date",
      value: otherMCState.clockOutDate,
    },
  ];
  const saveotherMCDetails = () => {
    otherMCState.clockInDate = moment(otherMCState.clockInDate).format(
      "YYYY-MM-DD"
    );
    otherMCState.clockOutDate = moment(otherMCState.clockOutDate).format(
      "YYYY-MM-DD"
    );
    trackPromise(
      axios
        .post(
          `${HOSTNAME}/sewadarOtherMCAttendances`,
          { ...otherMCState },
          { headers: { [AUTHORIZATION]: authTokenForAPI() } }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: res.data,
            });
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: res.data,
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Sewadar Details cannot be updated, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };
  const saveBeasDetails = () => {
    beasState.clockInDate = moment(beasState.clockInDate).format("YYYY-MM-DD");
    beasState.clockOutDate = moment(beasState.clockOutDate).format(
      "YYYY-MM-DD"
    );
    trackPromise(
      axios
        .post(
          `${HOSTNAME}/sewadarBeasAttendances`,
          { ...beasState },
          { headers: { [AUTHORIZATION]: authTokenForAPI() } }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: res.data,
            });
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: res.data,
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Sewadar Details cannot be updated, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav
        navLinks={navLinks}
        pageName="Sewadar Additional Details"
      />
      <h2>Additional Attendance Details</h2>
      <Box border={`2px solid ${primaryColor}`} borderRadius={5} mb={2}>
        <Accordion style={{ background: "transparent", boxShadow: 'none' }} expanded={toggleBeasSection} onChange={() => setToggleBeasSection(prev => !prev)}>
          <AccordionSummary expandIcon={<ExpandMore sx={{ border: `1px solid ${primaryColor}`, borderRadius: '100%', padding: '5px' }} />} >
            <h2>Beas</h2>
          </AccordionSummary>
          <AccordionDetails>
            <GenericForm fieldOptions={beasOps} onFieldChange={onBeasFieldChange} />
            {Object.keys(beasSewadarDetails).length > 0 && (
              <Grid container fontSize={"14px"}>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  Name: <b>{beasSewadarDetails.name}</b>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  Centre: <b>{beasSewadarDetails.centre}</b>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  Sub-Centre: <b>{beasSewadarDetails.subCentre}</b>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  Department: <b>{beasSewadarDetails.department}</b>
                </Grid>
              </Grid>
            )}
            <Grid container justifyContent="right" p={2}>
              <Grid item lg={2} md={3} sm={4} xs={8}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ margin: "20px" }}
                  onClick={saveBeasDetails}
                >
                  Save Beas Details
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box border={`2px solid ${primaryColor}`} borderRadius={5} mb={2}>
        <Accordion style={{ background: "transparent", boxShadow: 'none' }} expanded={!toggleBeasSection} onChange={() => setToggleBeasSection(prev => !prev)}>
        <AccordionSummary expandIcon={<ExpandMore sx={{ border: `1px solid ${primaryColor}`, borderRadius: '100%', padding: '5px' }} />} >
            <h2>Other Major Centre</h2>
          </AccordionSummary>
          <AccordionDetails>
            <GenericForm
              fieldOptions={otherMCOps}
              onFieldChange={onMCFieldChange}
            />
            {Object.keys(OtherSewadarDetails).length > 0 && (
              <Grid container pt={2} fontSize={"14px"}>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  Name: <b>{OtherSewadarDetails.name}</b>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  Centre: <b>{OtherSewadarDetails.centre}</b>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  Sub-Centre: <b>{OtherSewadarDetails.subCentre}</b>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  Department: <b>{OtherSewadarDetails.department}</b>
                </Grid>
              </Grid>
            )}
            <Grid container justifyContent="right" p={2}>
              <Grid item lg={2} md={3} sm={4} xs={8}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ margin: "20px" }}
                  onClick={saveotherMCDetails}
                >
                  Save Other MC Details
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default SewadarAddtional;
