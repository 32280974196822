import { Alert, Button, Snackbar } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AUTHORIZATION, HOSTNAME } from "../../common/constants";
import GenericForm from "../../components/GenericForm";
import HorizontalNav from "../../components/HorizontalNav";
import authTokenForAPI from "../../utils/authTokenForAPI";
import { arrayData, isAPISuccess } from "../../utils/commonUtils";

const ManualAttendance = () => {
  const [fieldState, setFieldState] = useState({});
  const [subcentres, setSubcentres] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const onFieldChange = (key, val) => {
    setFieldState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  useEffect(() => {
    console.log(fieldState);
  }, [fieldState]);

  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/sub-centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setSubcentres(arrayData(res.data));
        })
    );
  }, []);

  const reportOps = [
    {
      key: "sewadarId",
      type: "textbox",
      label: "Sewadar ID",
      value: fieldState.sewadarId,
    },

    {
      key: "from",
      type: "datetimepicker",
      label: "From",
      value: fieldState.from,
    },
    {
      key: "to",
      type: "datetimepicker",
      label: "To",
      value: fieldState.to,
    },
    {
      key: "centre",
      type: "dropdown",
      label: "Centre",
      value: fieldState.centre,
      options: subcentres,
    },
  ];
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  const saveDetails = () => {
    const payload = {
      sewadarId: fieldState.sewadarId,
      clockIn: moment(fieldState.from).utc().format("YYYY-MM-DD hh:mm:ss"),
      clockOut: moment(fieldState.to).utc().format("YYYY-MM-DD hh:mm:ss"),
      centre: fieldState.centre,
    };
    if (!fieldState.sewadarId || !fieldState.centre) {
      setSnack({
        open: true,
        severity: "error",
        msg: "Please enter all the details!",
      });
      return;
    }
    trackPromise(
      axios
        .post(
          `${HOSTNAME}/sewadarAttendances/manual/${fieldState.sewadarId}`,
          {
            ...payload,
          },
          { headers: { [AUTHORIZATION]: authTokenForAPI() } }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: res.data,
            });
            window.location.reload();
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: res.data,
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Sewadar Attendance cannot be uploaded, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Manual Attendance Time" />
      <h2>Manual Attendance Time</h2>
      <GenericForm fieldOptions={reportOps} onFieldChange={onFieldChange} />
      <Button
        variant="contained"
        sx={{ margin: "20px auto", display: "block" }}
        onClick={saveDetails}
      >
        Save Attendance Details
      </Button>
    </>
  );
};

export default ManualAttendance;
