import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Link } from "@mui/material";
import { primaryColor } from "../utils/styleObjs";
import CentreInfo from "./CentreInfo";

const HorizontalNav = ({ navLinks, pageName }) => {
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        sx={{
          color: primaryColor,
          fontWeight: "600",
          padding: "5px 0",
          borderBottom: "1px solid",
        }}
      >
        {navLinks.map((link) => {
          return (
            <Link
              underline="hover"
              key={link.key}
              color="inherit"
              href={link.path}
            >
              {link.label}
            </Link>
          );
        })}
        <Link underline="none" key={navLinks.length} color="inherit">
          {pageName}
        </Link>
      </Breadcrumbs>
      <CentreInfo />
    </>
  );
};

export default HorizontalNav;
