import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import HorizontalNav from "../../components/HorizontalNav";
import GenericForm from "../../components/GenericForm";
import { useEffect, useState } from "react";
import { fieldOptions } from "./gateFieldOps";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { AUTHORIZATION, securityBearerToken } from "../../common/constants";
import { isAPISuccess } from "../../utils/commonUtils";

const GateCount = () => {
  const [fieldState, setFieldState] = useState({});
  const [gates, setGates] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  useEffect(() => {
    trackPromise(
      axios
        .get(`http://20.197.54.6:8080/gate/`, {
          headers: { [AUTHORIZATION]: securityBearerToken },
        })
        .then((res) => {
          setGates(
            res.data.map((gate) => {
              return { key: gate.ID, value: gate.ID, label: gate.name };
            })
          );
        })
    );
    // trackPromise(
    //   axios
    //     .get(`${HOSTNAME}/sub-centres`, {
    //       headers: { [AUTHORIZATION]: authTokenForAPI() },
    //     })
    //     .then((res) => {
    //       setSubcentres(arrayData(res.data));
    //     })
    // );
    // trackPromise(
    //   axios
    //     .get(`${HOSTNAME}/departments`, {
    //       headers: { [AUTHORIZATION]: authTokenForAPI() },
    //     })
    //     .then((res) => {
    //       setDepartment(arrayData(res.data));
    //     })
    // );
  }, []);

  const onFieldChange = (key, val) => {
    setFieldState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const saveGateEntry = (add) => {
    const count = add
      ? Number(fieldState?.count)
      : Number(fieldState?.count * -1);
    trackPromise(
      axios
        .post(
          `http://20.197.54.6:8080/gate/entry`,
          {
            gate_id: fieldState.gate,
            count,
          },
          {
            headers: { [AUTHORIZATION]: securityBearerToken },
          }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: "Gate entry updated successfully",
            });
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Gate entry cannot be updated, please try again.",
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Gate entry cannot be updated, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Gate Count" />
      <h2>Gate Count</h2>
      <GenericForm
        fieldOptions={fieldOptions(fieldState, gates)}
        onFieldChange={onFieldChange}
      />

      <Grid container justifyContent="space-evenly">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
              background:'green'
            }}
            onClick={() => saveGateEntry(true)}
          >
            Count In
          </Button>
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
              background: "red"
          }}
            onClick={() => saveGateEntry(false)}
          >
            Count Out
          </Button>
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={8} />
      </Grid>
    </>
  );
};

export default GateCount;
