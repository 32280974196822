import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import HorizontalNav from "../../../components/HorizontalNav";
import GenericForm from "../../../components/GenericForm";
import { fieldOptions } from "./addSewadarUtils";
import axios from "axios";
import { AUTHORIZATION, HOSTNAME } from "../../../common/constants";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { arrayData, isAPISuccess } from "../../../utils/commonUtils";
import { trackPromise } from "react-promise-tracker";
import {
  primaryColor,
  secondaryColor,
  textboxStyle,
} from "../../../utils/styleObjs";
import moment from "moment";

const AddSewadar = () => {
  const [fieldState, setFieldState] = useState({});
  const [photo, setPhoto] = useState();
  const [centres, setCentres] = useState([]);
  const [subcentres, setSubcentres] = useState([]);
  const [department, setDepartment] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  const onFieldChange = (key, val) => {
    setFieldState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };

  const saveSewadarData = () => {
    console.log(fieldState);
    fieldState.birthDate = moment(fieldState.birthDate).format("YYYY-MM-DD");
    fieldState.initiationDate = moment(fieldState.initiationDate).format(
      "YYYY-MM-DD"
    );
    const authToken = authTokenForAPI();
    const formData = new FormData();
    formData.append("sewadar", JSON.stringify({ ...fieldState }));
    formData.append("photo", photo);
    trackPromise(
      axios
        .post(`${HOSTNAME}/sewadars`, formData, {
          headers: {
            [AUTHORIZATION]: authToken,
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setFieldState({});
            setSnack({
              open: true,
              severity: "success",
              msg: "Sewadar added successfully",
            });
            window.location.reload();
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Sewadar cannot be added, please try again.",
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Sewadar cannot be added, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };

  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setCentres(arrayData(res.data));
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME}/sub-centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setSubcentres(arrayData(res.data));
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME}/departments`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setDepartment(arrayData(res.data));
        })
    );
  }, []);

  const checkPhotoSizeAndSave = (e) => {
    // 30,720 = 30kb
    const fileSize = e.target.files[0].size;
    const maxSize = 30720;
    if (fileSize > maxSize) {
      setSnack({
        open: true,
        severity: "error",
        msg: "Please upload the image less than 30kB",
      });
    } else {
      setPhoto(e.target.files[0]);
    }
  };

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Add Sewadar" />
      <h2>Add Sewadar</h2>
      <GenericForm
        fieldOptions={fieldOptions(fieldState, centres, subcentres, department)}
        onFieldChange={onFieldChange}
      />
      <Grid item lg={3} md={4} sm={6} xs={12} mt={2}>
        <h4>Upload Sewadar Image</h4>
        <TextField
          // key={idx}
          type="file"
          fullWidth
          sx={{
            ...textboxStyle,
            "& .MuiFormHelperText-root": {
              backgroundColor: secondaryColor,
              margin: "0",
              padding: "2px 5px",
            },
          }}
          // {...fieldOp}
          onInput={checkPhotoSizeAndSave}
          label=""
          helperText={
            <span
              style={{
                color: primaryColor,
                "& .MuiFormHelperText-root": {
                  backgroundColor: secondaryColor,
                },
              }}
            >
              Please upload passport size(30kB) photo of the sewadar
            </span>
          }
        />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
            }}
            onClick={saveSewadarData}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddSewadar;
