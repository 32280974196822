import { Alert, Button, Grid, Snackbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  AUTHORIZATION,
  BEAS,
  CENTRE,
  HOSTNAME,
  MAJOR_CENTRE,
  OTHER_CENTRE,
  OTHER_MAJOR_CENTRE,
  OWN_CENTRE,
} from "../../../common/constants";
import GenericForm from "../../../components/GenericForm";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { arrayData, isAPISuccess } from "../../../utils/commonUtils";
import { reportDateOps, reportOtherOps } from "./reportOps";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";
import { data } from "./temp";

const GenerateReport = () => {
  const [fieldState, setFieldState] = useState({});
  const [dateState, setDateState] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [centres, setCentres] = useState([]);
  const [subcentres, setSubcentres] = useState([]);
  const [department, setDepartment] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setCentres(arrayData(res.data));
          const selCentre = res.data.find((c) => {
            return c.name === localStorage.getItem(CENTRE);
          });
          if (localStorage.getItem(CENTRE) !== "SAHARANPUR-I") {
            setFieldState({
              ...fieldState,
              centre: localStorage.getItem(CENTRE),
            });
            setSubcentres(arrayData(selCentre?.subCentres));
          }
        })
    );
    if (localStorage.getItem(CENTRE) === "SAHARANPUR-I") {
      trackPromise(
        axios
          .get(`${HOSTNAME}/sub-centres`, {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          })
          .then((res) => {
            setSubcentres(arrayData(res.data));
          })
      );
    }
    trackPromise(
      axios
        .get(`${HOSTNAME}/departments`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          setDepartment(arrayData(res.data));
        })
    );
  }, []);

  const onFieldChange = (key, val) => {
    setFieldState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };
  const onDateFieldChange = (key, val) => {
    setDateState((prevState) => {
      return { ...prevState, [key]: val };
    });
  };
  const getParamString = () => {
    const paramArray = [];
    for (const key in dateState) {
      if (dateState[key]) {
        paramArray.push(
          `${key}=${moment(dateState[key]).format("YYYY-MM-DD")}`
        );
      }
    }
    for (const key in fieldState) {
      if (fieldState[key]) {
        paramArray.push(`${key}=${fieldState[key]}`);
      }
    }
    return paramArray.join("&");
  };
  const generateReport = () => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/sewadars/emailReport?${getParamString()}`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg:
                res?.data ||
                "Report will be generated and emailed in few mins.",
            });
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Report cannot be fetched, please try again.",
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Report cannot be fetched, please try again.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Generate Report" />
      <h2>Generate Report</h2>
      <Box>
        <GenericForm
          fieldOptions={reportDateOps(dateState)}
          onFieldChange={onDateFieldChange}
        />
      </Box>
      <Box mt={4}>
        <GenericForm
          fieldOptions={reportOtherOps(
            fieldState,
            centres,
            subcentres,
            department
          )}
          onFieldChange={onFieldChange}
        />
      </Box>
      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{ margin: "20px", float: "right" }}
            onClick={generateReport}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default GenerateReport;
