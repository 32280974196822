import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  AUTHORIZATION,
  HOSTNAME,
  securityBearerToken,
} from "./../../common/constants";
import HorizontalNav from "./../../components/HorizontalNav";
import authTokenForAPI from "./../../utils/authTokenForAPI";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "./../../utils/styleObjs";
// import { rows } from "./listSewadarUtils";

const GateList = () => {
  const [countList, setCountList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    trackPromise(
      axios
        .get(`http://20.197.54.6:8080/gate/count`, {
          headers: { [AUTHORIZATION]: securityBearerToken },
        })
        .then((res) => {
          console.log(res.data);
          setCountList(
            res.data.map((count) => {
              return { ...count, id: count.ID };
            })
          );
        })
    );
    trackPromise(
      axios
        .get(`http://20.197.54.6:8080/gate/total`, {
          headers: { [AUTHORIZATION]: securityBearerToken },
        })
        .then((res) => {
          console.log(res.data);
          setTotalCount(res.data);
        })
    );
  }, []);

  // const columns = [
  //   { field: "id", headerName: "ID" },
  //   { field: "name", headerName: "Centre name", width: 150 },
  //   { field: "prefix", headerName: "Prefix" },
  //   {
  //     field: "subcentres",
  //     headerName: "Sub Centres",
  //     description: "This column has a value getter and is not sortable.",
  //     sortable: false,
  //     width: 900,
  //     valueGetter: (params) =>
  //       params.row.subCentres
  //         .map(function (elem) {
  //           return elem.name;
  //         })
  //         .join(", "),
  //   },
  // ];

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  const columns = [
    { field: "ID", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Gate Name",
      width: 150,
    },
    {
      field: "count",
      headerName: "Count",
      width: 150,
    },
  ];

  return (
    <>
      <HorizontalNav navLinks={navLinks} pageName="Gate List" />
      <h2>Gate List</h2>
      <div style={{ maxWidth: "1100px" }}>
        <b> Total Count: {totalCount.count}</b>
        <br />
        <br />
        <br />
        <DataGrid
          density="compact"
          showCellRightBorder
          autoHeight
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          rows={countList}
          columns={columns}
        />
      </div>
    </>
  );
};

export default GateList;
