import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { testNumberRegex } from "../utils/testNumberRegex";
import { primaryColor, secondaryColor, textboxStyle } from "../utils/styleObjs";
import ClearIcon from "@mui/icons-material/Clear";

const GenericForm = ({ fieldOptions, onFieldChange }) => {
  return (
    <Grid container spacing={3} rowSpacing={4}>
      {fieldOptions.map((fieldOp, idx) => {
        if (fieldOp.type === "textbox") {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <TextField
                key={idx}
                onChange={(e) => onFieldChange(fieldOp.key, e.target.value)}
                fullWidth
                sx={textboxStyle}
                {...fieldOp}
              />
            </Grid>
          );
        }
        if (fieldOp.type === "textarea") {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <TextareaAutosize
                key={idx}
                onChange={(e) => onFieldChange(fieldOp.key, e.target.value)}
                style={{ minWidth: "300px" }}
                minRows={4}
                {...fieldOp}
              />
            </Grid>
          );
        }
        if (fieldOp.type === "dropdown") {
          const lOps = fieldOp.options;
          return (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControl fullWidth sx={textboxStyle}>
                <InputLabel>{fieldOp.label}</InputLabel>
                <Select
                  key={idx}
                  {...fieldOp}
                  value={fieldOp.value || ""}
                  onChange={(e) => {
                    return onFieldChange(fieldOp.key, e.target.value);
                  }}
                  endAdornment={
                    <ClearIcon
                      style={{
                        marginRight: "12px",
                        cursor: "pointer",
                        display: fieldOp.value ? "" : "none",
                      }}
                      onClick={() => {
                        return onFieldChange(fieldOp.key, "");
                      }}
                    />
                  }
                >
                  {lOps.map((op) => {
                    return (
                      <MenuItem key={op.key} value={op.value}>
                        {op.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          );
        }
        if (fieldOp.type === "datepicker") {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  key={idx}
                  inputFormat="dd-MM-yy"
                  onChange={(e) => {
                    return onFieldChange(fieldOp.key, e);
                  }}
                  {...fieldOp}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      sx={{
                        ...textboxStyle,
                        "& .MuiInputAdornment-root svg": {
                          color: primaryColor,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          );
        }
        if (fieldOp.type === "datetimepicker") {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      sx={{
                        ...textboxStyle,
                        "& .MuiInputAdornment-root svg": {
                          color: primaryColor,
                        },
                      }}
                    />
                  )}
                  label="DateTimePicker"
                  {...fieldOp}
                  onChange={(e) => {
                    return onFieldChange(fieldOp.key, e);
                  }}
                />
              </LocalizationProvider>
            </Grid>
          );
        }
        if (fieldOp.type === "number") {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <TextField
                key={idx}
                fullWidth
                sx={textboxStyle}
                // fieldOp to be placed here only
                {...fieldOp}
                type="text"
                onChange={(e) => {
                  if (testNumberRegex(e.target.value)) {
                    onFieldChange(fieldOp.key, e.target.value);
                  }
                }}
              />
            </Grid>
          );
        }
        if (fieldOp.type === "file") {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <TextField
                key={idx}
                type="file"
                fullWidth
                sx={{
                  ...textboxStyle,
                  "& .MuiFormHelperText-root": {
                    backgroundColor: secondaryColor,
                    margin: "0",
                    padding: "2px 5px",
                  },
                }}
                {...fieldOp}
                label=""
                onInput={(e) => onFieldChange(fieldOp.key, e.target.files[0])}
                helperText={
                  <span
                    style={{
                      color: primaryColor,
                      "& .MuiFormHelperText-root": {
                        backgroundColor: secondaryColor,
                      },
                    }}
                  >
                    Please upload Passport size photo of the sewadar
                  </span>
                }
              />
            </Grid>
          );
        }
        return (
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              key={idx}
              onChange={(e) => onFieldChange(fieldOp.key, e.target.value)}
              fullWidth
              sx={textboxStyle}
              {...fieldOp}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GenericForm;
