import moment from "moment";

export const isAPISuccess = (status) => {
  if (
    status === 200 ||
    status === 201 ||
    status === 202 ||
    status === 203 ||
    status === 204 ||
    status === 205 ||
    status === 206
  ) {
    return true;
  }
  return false;
};
export const arrayData = (data) => {
  const arr = [];
  data.forEach((val) => {
    arr.push({
      key: val.name,
      label: val.name,
      value: val.name,
    });
  });
  return arr;
};

export const getSatsangTime = (date) => {
  const month = moment(date).month();
  const day = moment(date).day();
  var time = "";
  switch (month) {
    case 1:
    case 12: {
      if (day === 0) {
        time = "10:00";
      } else {
        time = "09:00";
      }
      break;
    }
    case 2:
    case 11: {
      if (day === 0) {
        time = "09:30";
      } else {
        time = "08:30";
      }
      break;
    }
    default: {
      if (day === 0) {
        time = "09:00";
      } else {
        time = "08:00";
      }
      break;
    }
  }
  // if (month >= 2 && month < 10) {
  //   if (day === 0) {
  //     time = "09:00";
  //   } else {
  //     time = "08:00";
  //   }
  // } else {
  //   if (day === 0) {
  //     time = "09:30";
  //   } else {
  //     time = "09:00";
  //   }
  // }
  return time;
};
