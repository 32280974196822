import { Alert, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";
import { AUTHORIZATION, HOSTNAME } from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";

const LiveCount = () => {
  const [liveCount, setLiveCount] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  const history = useNavigate();

  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME}/sewadarAttendances/liveCount`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setLiveCount(res.data);
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Live Sewadar Count cannot be fetched.",
            });
          }
        })
        .catch(() => {
          setSnack({
            open: true,
            severity: "error",
            msg: "Live Sewadar Count cannot be fetched.",
          });
        })
    );
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Department name",
      width: 220,
      renderCell: (params) =>
        params.getValue(params.id, "id") || <em>Not Specified</em>,
    },
    {
      field: "count",
      headerName: "Number of Sewadar",
      width: 180,
      align: "center",
    },
  ];

  const getTableData = (dataObj) => {
    const dataRows = [];
    for (const val in dataObj) {
      dataRows.push({ id: val, name: val, count: dataObj[val] });
    }
    return dataRows;
  };

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Live Count" />
      <h2>Live Sewadar Count</h2>
      <Box mb={2} fontSize={16} fontWeight={500}>
        TOTAL COUNT:{" "}
        {Object.values(liveCount).reduce((partialSum, a) => partialSum + a, 0)}
      </Box>
      <div style={{ maxWidth: "400px" }}>
        <DataGrid
          density="compact"
          showCellRightBorder
          autoHeight
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          rows={getTableData(liveCount)}
          columns={columns}
          onRowClick={(param) => {
            history("/liveCountDept", { state: { department: param.id } });
          }}
        />
      </div>
    </>
  );
};

export default LiveCount;
