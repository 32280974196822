import { Alert, Button, Grid, Snackbar } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AUTHORIZATION, HOSTNAME_PATHI } from "../../../common/constants";
import GenericForm from "../../../components/GenericForm";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";

const ConfigureCentre = () => {
  const [editFlow, setEditFlow] = useState(false);
  const [data, setData] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const params = useParams();
  const history = useNavigate();
  useEffect(() => {
    if (window.location.pathname.includes("/edit/")) setEditFlow(true);
  }, []);
  useEffect(() => {
    if (editFlow) {
      trackPromise(
        axios
          .get(`${HOSTNAME_PATHI}/centres/${params.id}`, {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          })
          .then((res) => {
            setData({
              ...res.data,
              midWeek: res.data?.satsangDays?.find((e) => e !== "SUNDAY"),
            });
          })
      );
    }
  }, [editFlow, params.id]);

  const onFieldChange = (key, val) => {
    setData((prevState) => {
      return { ...prevState, [key]: val };
    });
  };
  const navLinks = [
    { key: 0, label: "Home", path: "/home" },
    { key: 1, label: "Centre", path: "/centre" },
  ];
  // private Integer code;
  // private String centreName;
  // private String attachedTo;
  // private String address1;
  // private String address2;
  // private Integer stdCode;
  // private Integer telephoneNumber;
  // private List<DayOfWeek> satsangDays;
  const fieldOptions = [
    {
      key: "code",
      type: "textbox",
      label: "ID",
      value: data.code,
      disabled: true,
      focused: editFlow,
    },
    {
      key: "centreName",
      type: "textbox",
      label: "Satsang Place",
      value: data.centreName,
      focused: editFlow,
    },
    {
      key: "attachedTo",
      type: "textbox",
      label: "Attached To",
      value: data.attachedTo,
      focused: editFlow,
    },
    {
      key: "address1",
      type: "textbox",
      label: "Address 1",
      value: data.address1,
      focused: editFlow,
    },
    {
      key: "address2",
      type: "textbox",
      label: "Address 2",
      value: data.address2,
      focused: editFlow,
    },
    {
      key: "stdCode",
      type: "textbox",
      label: "STD Code",
      value: data.stdCode,
      focused: editFlow,
    },
    {
      key: "telephoneNumber",
      type: "textbox",
      label: "Telephone Number",
      value: data.telephoneNumber,
      focused: editFlow,
    },
    {
      key: "midWeek",
      type: "dropdown",
      label: "Mid Week Satsang Day",
      value: data.midWeek,
      options: [
        { id: 1, label: "MONDAY", value: "MONDAY" },
        { id: 2, label: "TUESDAY", value: "TUESDAY" },
        { id: 3, label: "WEDNESDAY", value: "WEDNESDAY" },
        { id: 4, label: "THURSDAY", value: "THURSDAY" },
        { id: 5, label: "FRIDAY", value: "FRIDAY" },
        { id: 6, label: "SATURDAY", value: "SATURDAY" },
      ],
      focused: editFlow,
    },
  ];

  const saveData = () => {
    const authToken = authTokenForAPI();
    data.satsangDays = [data.midWeek, "SUNDAY"];
    if (editFlow) {
      trackPromise(
        axios
          .put(`${HOSTNAME_PATHI}/centres/${params.id}`, data, {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          })
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Centre added successfully",
              });
              history("/centre");
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Centre cannot be added, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Centre cannot be added, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    } else {
      trackPromise(
        axios
          .post(`${HOSTNAME_PATHI}/centres`, data, {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          })
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Centre added successfully",
              });
              history("/centre");
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Centre cannot be added, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Centre cannot be added, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    }
  };
  const configConstant = editFlow ? "Edit" : "Add";
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav
        navLinks={navLinks}
        pageName={`${configConstant} Centre`}
      />
      <h2>{configConstant} Centre</h2>
      <GenericForm
        fieldOptions={editFlow ? fieldOptions : fieldOptions.slice(1)}
        onFieldChange={onFieldChange}
      />
      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
            }}
            onClick={saveData}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigureCentre;
