import React, { useEffect, useState } from "react";
import QuaggaScanner from "./QuaggaScanner";
import Result from "./Result";
import { Alert, Snackbar, TextField } from "@mui/material";
import { textboxStyle } from "../../utils/styleObjs";
import AfterScan from "./AfterScan";
import HorizontalNav from "../../components/HorizontalNav";
import axios from "axios";
import {
  AUTHORIZATION,
  CAMERA_TIMEOUT,
  HOSTNAME,
} from "../../common/constants";
import authTokenForAPI from "../../utils/authTokenForAPI";
import { isAPISuccess } from "../../utils/commonUtils";
import { trackPromise } from "react-promise-tracker";
import ZXingScanner from "./ZXingScanner";

const ScanController = ({
  defaultScanning = true,
  defaultAfterScan = false,
}) => {
  const [scanning, setScan] = useState(defaultScanning);
  const [showAfterScan, setShowAfterScan] = useState(defaultAfterScan);
  const [allBtnDisabled, setAllBtnDisabled] = useState(false);
  const [res, setRes] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  const onDetected = (result) => {
    console.log(result);
    setRes(result);
  };
  useEffect(() => {
    // waitFor();
    if (Object.keys(res).length > 0) {
      setScan(false);
      setShowAfterScan(true);
    }
  }, [res]);

  const waitFor = () => {
    setTimeout(() => {
      if (res === "" && scanning && !showAfterScan) {
        setSnack({
          open: true,
          severity: "error",
          msg: "Barcode not clear, please enter manually.",
        });
        setScan(false);
        setShowAfterScan(true);
      }
    }, CAMERA_TIMEOUT);
  };

  const clockIn = () => {
    trackPromise(
      axios
        .post(
          `${HOSTNAME}/sewadarAttendances/${Number(res)}`,
          {},
          { headers: { [AUTHORIZATION]: authTokenForAPI() } }
        )
        .then((resp) => {
          if (isAPISuccess(resp.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: "Sewadar Clocked In successfully",
            });
            setAllBtnDisabled(true);
            setTimeout(() => window.location.reload(), 5000);
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Some error occured. Please try later.",
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Some error occured. Please try later.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };

  const clockOut = () => {
    trackPromise(
      axios
        .put(
          `${HOSTNAME}/sewadarAttendances/${res}`,
          {},
          { headers: { [AUTHORIZATION]: authTokenForAPI() } }
        )
        .then((res) => {
          if (isAPISuccess(res.status)) {
            setSnack({
              open: true,
              severity: "success",
              msg: "Sewadar Clocked Out successfully",
            });
            setAllBtnDisabled(true);
            setTimeout(() => window.location.reload(), 5000);
            clearTimeout();
          } else {
            setSnack({
              open: true,
              severity: "error",
              msg: "Some error occured. Please try later.",
            });
          }
        })
        .catch((err) => {
          const errMsg =
            err?.response?.data?.message ||
            "Some error occured. Please try later.";
          setSnack({
            open: true,
            severity: "error",
            msg: errMsg,
          });
        })
    );
  };
  const navLinks = [{ key: 0, label: "Home", path: "/home" }];
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName="Mark Attendance" />
      <h2>Mark Attendance</h2>

      {showAfterScan && (
        <AfterScan
          res={res}
          setRes={setRes}
          clockIn={clockIn}
          clockOut={clockOut}
          allBtnDisabled={allBtnDisabled}
        />
      )}
      {scanning ? (
        // <Scanner onDetected={onDetected} scanning={scanning} />
        <ZXingScanner onDetected={onDetected} />
      ) : null}
    </>
  );
};

export default ScanController;
