import { BrowserMultiFormatReader } from "@zxing/library";
import { useEffect, useRef } from "react";

const ZXingScanner = ({ onDetected }) => {
  const vidRef = useRef(null);
  useEffect(() => {
    let selectedDeviceId;
    const codeReader = new BrowserMultiFormatReader();
    console.log("ZXing code reader initialized");

    codeReader
      .getVideoInputDevices()
      .then((videoInputDevices) => {
        const sourceSelect = document.getElementById("sourceSelect");
        selectedDeviceId = videoInputDevices[0].deviceId;
        if (videoInputDevices.length > 1) {
          videoInputDevices.forEach((element) => {
            const sourceOption = document.createElement("option");
            sourceOption.text = element.label;
            sourceOption.value = element.deviceId;
            sourceSelect.appendChild(sourceOption);
          });

          sourceSelect.onchange = () => {
            selectedDeviceId = sourceSelect.value;
          };

          // const sourceSelectPanel =
          //   document.getElementById("sourceSelectPanel");
          // sourceSelectPanel.style.display = "block";
        }

        codeReader
          .decodeOnceFromVideoDevice(undefined, "videoPlayer")
          .then((result) => {
            console.log(result);
            console.log(vidRef.current);
            onDetected(result.text);
            codeReader.reset();
          })
          .catch((err) => {
            console.error(err);
            // document.getElementById("result").textContent = err;
          });
        console.log(
          `Started continous decode from camera with id ${selectedDeviceId}`
        );
      })
      .catch((err) => {
        console.error(err);
      });
    return () => codeReader.reset();
  }, []);

  return (
    <>
      <div>
        <video id="videoPlayer" width="400" height="300" ref={vidRef}></video>
      </div>
      <div id="sourceSelectPanel" style={{ display: "none" }}>
        <label for="sourceSelect">Change video source:</label>
        <select id="sourceSelect"></select>
      </div>
    </>
  );
};
export default ZXingScanner;
