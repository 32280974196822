export const fieldOptions = (fieldState, centres, subcentres, department) => [
  {
    key: "name",
    type: "textbox",
    label: "Name of sewadar",
    value: fieldState.name,
  },
  {
    key: "relativeName",
    type: "textbox",
    label: "S/O W/O D/O",
    value: fieldState.relativeName,
  },
  {
    key: "gender",
    type: "dropdown",
    label: "Gender",
    value: fieldState.gender,
    options: [
      {
        key: "M",
        label: "Male",
        value: "M",
      },
      {
        key: "F",
        label: "Female",
        value: "F",
      },
    ],
  },
  {
    key: "address",
    type: "textbox",
    label: "Address",
    value: fieldState.address,
  },
  {
    key: "city",
    type: "textbox",
    label: "City/Village",
    value: fieldState.city,
  },
  {
    key: "pinCode",
    type: "number",
    label: "Pincode",
    value: fieldState.pinCode,
  },
  {
    key: "mobileNumber",
    type: "number",
    label: "Contact Number",
    value: fieldState.mobileNumber,
  },
  {
    key: "emergencyNumber",
    type: "number",
    label: "Emergency Number",
    value: fieldState.emergencyNumber,
  },
  {
    key: "birthDate",
    type: "datepicker",
    label: "Date of Birth",
    value: fieldState.birthDate,
    error:false
  },
  {
    key: "initiationStatus",
    type: "textbox",
    label: "Initiation Status",
    value: fieldState.initiationStatus,
  },
  {
    key: "initiationDate",
    type: "datepicker",
    label: "Date of Initiation",
    value: fieldState.initiationDate,
  },
  {
    key: "qualification",
    type: "textbox",
    label: "Qualification",
    value: fieldState.qualification,
  },
  {
    key: "profession",
    type: "textbox",
    label: "Profession",
    value: fieldState.profession,
  },
  {
    key: "maritalStatus",
    type: "textbox",
    label: "Marital Status",
    value: fieldState.maritalStatus,
  },

  {
    key: "bloodGroup",
    type: "textbox",
    label: "Blood Group",
    value: fieldState.bloodGroup,
  },

  {
    key: "department",
    type: "dropdown",
    // type: "textbox",
    label: "Department at Native Satsang Ghar",
    options: department,
    value: fieldState.department,
  },
  {
    key: "aadharNumber",
    type: "number",
    label: "Aadhar Number",
    value: fieldState.aadharNumber,
  },
  {
    key: "zoneBatchNumber",
    type: "textbox",
    label: "Zone Badge Number",
    value: fieldState.zoneBatchNumber,
  },
  {
    key: "zoneDepartment",
    type: "dropdown",
    label: "Zone Department (Sewa at M.C.)",
    options: department,
    value: fieldState.zoneDepartment,
  },
  {
    key: "introducedBy",
    type: "textbox",
    label: "Introduced By",
    value: fieldState.introducedBy,
  },
  {
    key: "subCentre",
    // type: "textbox",
    type: "dropdown",
    label: "Sub Centre",
    options: subcentres,
    value: fieldState.subCentre,
  },
  {
    key: "centre",
    type: "dropdown",
    // type: "textbox",
    label: "Centre",
    options: centres,
    value: fieldState.centre,
  },
  {
    key: "remarks",
    type: "textarea",
    label: "Remarks",
    value: fieldState.remarks,
  },
];
