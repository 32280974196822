import { Alert, Button, Grid, Snackbar } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AUTHORIZATION, HOSTNAME_PATHI } from "../../../common/constants";
import GenericForm from "../../../components/GenericForm";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";

const ConfigureLedger = () => {
  const [editFlow, setEditFlow] = useState(false);
  const [centreOps, setCentreOps] = useState([]);
  const [designationOps, setDesignationOps] = useState([]);
  const [data, setData] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const params = useParams();
  const history = useNavigate();
  useEffect(() => {
    if (window.location.pathname.includes("edit")) setEditFlow(true);
  }, []);
  useEffect(() => {
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/centres`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);

          setCentreOps(
            res.data.map((v) => ({
              ...v,
              id: v.code,
              midWeek: v.satsangDays?.find((e) => e !== "SUNDAY"),
              label: v.centreName,
              value: v.code,
            }))
          );
        })
    );
    trackPromise(
      axios
        .get(`${HOSTNAME_PATHI}/designations`, {
          headers: { [AUTHORIZATION]: authTokenForAPI() },
        })
        .then((res) => {
          console.log(res);
          setDesignationOps(
            res.data.map((v) => ({
              ...v,
              id: v.ref,
              label: v.designation,
              value: v.ref,
            }))
          );
        })
    );
  }, []);
  useEffect(() => {
    if (editFlow) {
      trackPromise(
        axios
          .get(`${HOSTNAME_PATHI}/ledgers/${params.id}`, {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          })
          .then((res) => {
            setData({ ...res.data });
          })
      );
    }
  }, [params.id, editFlow]);

  const onFieldChange = (key, val) => {
    setData((prevState) => {
      return { ...prevState, [key]: val };
    });
  };
  const navLinks = [
    { key: 0, label: "Home", path: "/home" },
    { key: 1, label: "Ledger", path: "/ledger" },
  ];
  const fieldOptions = [
    {
      key: "ref",
      type: "textbox",
      label: "ID",
      value: data.ref,
      disabled: true,
      focused: editFlow,
    },
    {
      key: "designationRef",
      type: "dropdown",
      label: "Designation",
      value: data.designationRef,
      options: designationOps,
      focused: editFlow,
    },
    {
      key: "name",
      type: "textbox",
      label: "Name",
      value: data.name,
      focused: editFlow,
    },
    {
      key: "address1",
      type: "textbox",
      label: "Address 1",
      value: data.address1,
      focused: editFlow,
    },
    {
      key: "address2",
      type: "textbox",
      label: "Address 2",
      value: data.address2,
      focused: editFlow,
    },
    {
      key: "stdCode",
      type: "textbox",
      label: "STD code",
      value: data.stdCode,
      focused: editFlow,
    },
    {
      key: "telephoneNumber",
      type: "textbox",
      label: "Contact",
      value: data.telephoneNumber,
      focused: editFlow,
    },
    {
      key: "residence",
      type: "textbox",
      label: "Residence Num",
      value: data.residence,
      focused: editFlow,
    },
    {
      key: "city",
      type: "textbox",
      label: "City",
      value: data.city,
      focused: editFlow,
    },
    {
      key: "mobile",
      type: "number",
      label: "Mobile Num",
      value: data.mobile,
      focused: editFlow,
    },
    {
      key: "dateOfBirth",
      type: "datepicker",
      label: "DOB",
      value: data.dateOfBirth,
      focused: editFlow,
    },
    {
      key: "dateOfInitiation",
      type: "datepicker",
      label: "Date of Initiation",
      value: data.dateOfInitiation,
      focused: editFlow,
    },
    {
      key: "letterNumberOfAppointment",
      type: "textbox",
      label: "Letter Number Of Appointment",
      value: data.letterNumberOfAppointment,
      focused: editFlow,
    },
    {
      key: "dateOfAppointment",
      type: "datepicker",
      label: "Date Of Appointment",
      value: data.dateOfAppointment,
      focused: editFlow,
    },
    {
      key: "centreCode",
      type: "dropdown",
      label: "Centre",
      value: data.centreCode,
      options: centreOps,
      focused: editFlow,
    },
  ];
  // Integer ref;
  // Designation designation;
  // String name;
  // String address1;
  // String address2;
  // Integer stdCode;
  // Integer telephoneNumber;
  // String residence;
  // String city;
  // Long mobile;
  // LocalDate dateOfBirth;
  // LocalDate dateOfInitiation;
  // String letterNumberOfAppointment;
  // LocalDate dateOfAppointment;
  // Centre centre;
  const saveData = () => {
    const authToken = authTokenForAPI();
    if (editFlow) {
      trackPromise(
        axios
          .put(`${HOSTNAME_PATHI}/ledgers/${params.id}`, data, {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          })
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Ledger added successfully",
              });
              history("/ledger");
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Ledger cannot be added, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Ledger cannot be added, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    } else {
      trackPromise(
        axios
          .post(`${HOSTNAME_PATHI}/ledgers`, data, {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          })
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Ledger added successfully",
              });
              history("/ledger");
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Ledger cannot be added, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Ledger cannot be added, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    }
  };
  const configConstant = editFlow ? "Edit" : "Add";
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav
        navLinks={navLinks}
        pageName={`${configConstant} Ledger`}
      />
      <h2>{configConstant} Ledger</h2>
      <GenericForm
        fieldOptions={editFlow ? fieldOptions : fieldOptions.slice(1)}
        onFieldChange={onFieldChange}
      />
      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
            }}
            onClick={saveData}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigureLedger;
