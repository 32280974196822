import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { AUTHORIZATION, HOSTNAME } from "../../../common/constants";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import {
  primaryColor,
  secondaryColor,
  ternaryColor,
} from "../../../utils/styleObjs";
// import { rows } from "./listSewadarUtils";

const ListDepartments = () => {
  const [deptList, setDeptList] = useState([]);

  useEffect(() => {
    trackPromise(
      trackPromise(
        axios
          .get(`${HOSTNAME}/departments`, {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          })
          .then((res) => {
            setDeptList(res.data);
          })
      )
    );
  }, []);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Department name", width: 250 },
  ];

  const navLinks = [{ key: 0, label: "Home", path: "/home" }];

  return (
    <>
      <HorizontalNav navLinks={navLinks} pageName="List of Departments" />
      <h2>List Departments</h2>
      <div style={{ maxWidth: "400px" }}>
        <DataGrid
          density="compact"
          showCellRightBorder
          autoHeight
          sx={{
            borderColor: primaryColor,
            backgroundColor: ternaryColor,
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: secondaryColor,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: primaryColor,
              color: "white",
              fontWeight: "600",

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
          rows={deptList}
          columns={columns}
        />
      </div>
    </>
  );
};

export default ListDepartments;
