import { Alert, Button, Grid, Snackbar } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AUTHORIZATION, HOSTNAME_PATHI } from "../../../common/constants";
import GenericForm from "../../../components/GenericForm";
import HorizontalNav from "../../../components/HorizontalNav";
import authTokenForAPI from "../../../utils/authTokenForAPI";
import { isAPISuccess } from "../../../utils/commonUtils";

const ConfigureShabadMedia = () => {
  const [editFlow, setEditFlow] = useState(false);
  const [data, setData] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const params = useParams();
  const history = useNavigate();
  useEffect(() => {
    if (window.location.pathname.includes("edit")) setEditFlow(true);
  }, []);
  useEffect(() => {
    if (editFlow) {
      trackPromise(
        axios
          .get(`${HOSTNAME_PATHI}/shabadMedias/${params.id}`, {
            headers: { [AUTHORIZATION]: authTokenForAPI() },
          })
          .then((res) => {
            setData({ ...res.data });
          })
      );
    }
  }, [editFlow, params.id]);

  const onFieldChange = (key, val) => {
    setData((prevState) => {
      return { ...prevState, [key]: val };
    });
  };
  const navLinks = [
    { key: 0, label: "Home", path: "/home" },
    { key: 1, label: "Media", path: "/shabadMedia" },
  ];
  const fieldOptions = [
    {
      key: "ref",
      type: "textbox",
      label: "ID",
      value: data.ref,
      disabled: true,
      focused: editFlow,
    },
    {
      key: "shabadMediaGroup",
      type: "textbox",
      label: "Media",
      value: data.shabadMediaGroup,
      focused: editFlow,
    },
  ];

  const saveData = () => {
    const authToken = authTokenForAPI();
    if (editFlow) {
      trackPromise(
        axios
          .put(`${HOSTNAME_PATHI}/shabadMedias/${params.id}`, data, {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          })
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Media added successfully",
              });
              history("/shabadMedia");
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Media cannot be added, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Media cannot be added, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    } else {
      trackPromise(
        axios
          .post(`${HOSTNAME_PATHI}/shabadMedias`, data, {
            headers: {
              [AUTHORIZATION]: authToken,
            },
          })
          .then((res) => {
            if (isAPISuccess(res.status)) {
              setSnack({
                open: true,
                severity: "success",
                msg: "Media added successfully",
              });
              history("/shabadMedia");
            } else {
              setSnack({
                open: true,
                severity: "error",
                msg: "Media cannot be added, please try again.",
              });
            }
          })
          .catch((err) => {
            const errMsg =
              err?.response?.data?.message ||
              "Media cannot be added, please try again.";
            setSnack({
              open: true,
              severity: "error",
              msg: errMsg,
            });
          })
      );
    }
  };
  const configConstant = editFlow ? "Edit" : "Add";
  return (
    <>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <HorizontalNav navLinks={navLinks} pageName={`${configConstant} Media`} />
      <h2>{configConstant} Media</h2>
      <GenericForm
        fieldOptions={editFlow ? fieldOptions : fieldOptions.slice(1)}
        onFieldChange={onFieldChange}
      />
      <Grid container justifyContent="center">
        <Grid item lg={2} md={3} sm={4} xs={8}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              margin: "20px",
              float: "right",
            }}
            onClick={saveData}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigureShabadMedia;
